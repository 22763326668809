(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeServicesDeleteController',PledgeServicesDeleteController);

    PledgeServicesDeleteController.$inject = ['$uibModalInstance', 'entity', 'PledgeServices'];

    function PledgeServicesDeleteController($uibModalInstance, entity, PledgeServices) {
        var vm = this;

        vm.pledgeServices = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PledgeServices.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
