(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('UserProfileDialogController', UserProfileDialogController);

    UserProfileDialogController.$inject = ['$location', '$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'UserProfile', 'User', 'VettedStatus', 'LanguageList'];

    function UserProfileDialogController ($location, $timeout, $scope, $stateParams, $uibModalInstance, $q, entity, UserProfile, User, VettedStatus, LanguageList) {
        var vm = this;

        vm.userProfile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.vettedstatuses = VettedStatus.query();
        vm.languagelists = LanguageList.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.userProfile.id !== null) {
                UserProfile.update(vm.userProfile, onSaveSuccess, onSaveError);
            } else {
                UserProfile.save(vm.userProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            //$state.go('dashboard-prepop');
            $scope.$emit('pledgemasterApp:userProfileUpdate', result);
            $uibModalInstance.close(result);

            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.redcrossVettedExpDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
