(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('HistoryController', HistoryController);

    HistoryController.$inject = ['DataUtils', 'History', 'HistorySearch'];

    function HistoryController(DataUtils, History, HistorySearch) {

        var vm = this;

        vm.histories = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            History.query(function(result) {
                vm.histories = result;
                vm.histories = vm.histories.reverse();
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            HistorySearch.query({query: vm.searchQuery}, function(result) {
                vm.histories = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
