(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeGoodsDetailController', PledgeGoodsDetailController);

    PledgeGoodsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PledgeGoods', 'GoodsCategoryOne', 'GoodsCategoryTwo', 'GoodsCategoryThree', 'GoodsQuantity', 'GoodsCondition'];

    function PledgeGoodsDetailController($scope, $rootScope, $stateParams, previousState, entity, PledgeGoods, GoodsCategoryOne, GoodsCategoryTwo, GoodsCategoryThree, GoodsQuantity, GoodsCondition) {
        var vm = this;

        vm.pledgeGoods = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:pledgeGoodsUpdate', function(event, result) {
            vm.pledgeGoods = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
