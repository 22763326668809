(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('AccommodationLocalAmenitiesSearch', AccommodationLocalAmenitiesSearch);

    AccommodationLocalAmenitiesSearch.$inject = ['$resource'];

    function AccommodationLocalAmenitiesSearch($resource) {
        var resourceUrl =  'api/_search/accommodation-local-amenities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
