(function() {
    'use strict';
    angular
        .module('pledgemasterApp')
        .factory('Profile', ProfileAddress);

    ProfileAddress.$inject = ['Profile', 'Address'];

    function ProfileAddress ($resource, Address) {

    var resourceUrl =  'api/profiles/address';

    return $resource(resourceUrl, {}, {
        'query': { method: 'GET', isArray: true},
        'get': {
            method: 'GET',
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        },
        'update': { method:'PUT' }
    });
}

})();
