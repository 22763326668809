(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('PledgeAccommodationSearch', PledgeAccommodationSearch);

    PledgeAccommodationSearch.$inject = ['$resource'];

    function PledgeAccommodationSearch($resource) {
        var resourceUrl =  'api/_search/pledge-accommodations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
