(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossScreeningTwoAssessmentStatusDetailController', RedcrossScreeningTwoAssessmentStatusDetailController);

    RedcrossScreeningTwoAssessmentStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RedcrossScreeningTwoAssessmentStatus'];

    function RedcrossScreeningTwoAssessmentStatusDetailController($scope, $rootScope, $stateParams, previousState, entity, RedcrossScreeningTwoAssessmentStatus) {
        var vm = this;

        vm.redcrossScreeningTwoAssessmentStatus = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:redcrossScreeningTwoAssessmentStatusUpdate', function(event, result) {
            vm.redcrossScreeningTwoAssessmentStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
