(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('ServiceCategoryThreeDetailController', ServiceCategoryThreeDetailController);

    ServiceCategoryThreeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ServiceCategoryThree', 'ServiceCategoryTwo'];

    function ServiceCategoryThreeDetailController($scope, $rootScope, $stateParams, previousState, entity, ServiceCategoryThree, ServiceCategoryTwo) {
        var vm = this;

        vm.serviceCategoryThree = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:serviceCategoryThreeUpdate', function(event, result) {
            vm.serviceCategoryThree = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
