(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommodationFacilitiesDeleteController',AccommodationFacilitiesDeleteController);

    AccommodationFacilitiesDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccommodationFacilities'];

    function AccommodationFacilitiesDeleteController($uibModalInstance, entity, AccommodationFacilities) {
        var vm = this;

        vm.accommodationFacilities = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccommodationFacilities.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
