(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RegisterController', RegisterController);


    RegisterController.$inject = ['$state', '$translate', '$timeout', 'Auth', 'LoginService'];

    function RegisterController ($state, $translate, $timeout, Auth, LoginService) {
        var vm = this;

       //
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;

        // if ($state.$current.self.name == "register-ac-success"){
        //     vm.success = 'OK';
        // }

        $timeout(function (){angular.element('#login').focus();});

        // function registerModal() {
        //     vm.confirmPassword = vm.registerAccount.password;
        //     register();
        // }

        function register () {
            //if (vm.$state.includes('admin'))
                ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/click/registration"});
            //else ga('send', 'pageView', "/admin/virtual/register/click/registration");

            console.log("Register");
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                vm.registerAccount.langKey = 'en';
                vm.registerAccount.login = vm.registerAccount.email;
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;

                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.success = 'OK';
                    Auth.login({
                        username: vm.username,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function () {
                        vm.authenticationError = false;
                        // $uibModalInstance.close();
                        if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                            $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                            $state.go('dashboard-prepop');
                        }

                        $rootScope.$broadcast('authenticationSuccess');

                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        if (Auth.getPreviousState()) {
                            var previousState = Auth.getPreviousState();
                            Auth.resetPreviousState();
                            $state.go(previousState.name, previousState.params);
                        }
                    }).catch(function () {
                        vm.authenticationError = true;
                    });
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });
            }
        }
    }
})();
