(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('LanguageListSearch', LanguageListSearch);

    LanguageListSearch.$inject = ['$resource'];

    function LanguageListSearch($resource) {
        var resourceUrl =  'api/_search/language-lists/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
