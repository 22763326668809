(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeServiceDetailController', PledgeServiceDetailController);

    PledgeServiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PledgeService', 'ServiceCategoryOne'];

    function PledgeServiceDetailController($scope, $rootScope, $stateParams, previousState, entity, PledgeService, ServiceCategoryOne) {
        var vm = this;

        vm.pledgeService = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:pledgeServiceUpdate', function(event, result) {
            vm.pledgeService = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
