(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .directive('pushBottom', pushBottomDirective)

    pushBottomDirective.$inject = ['$window'];

    // TODO doesn't work well with scrolling
    function pushBottomDirective($window) {
        function resizeElement($element){
            $element.css({
                position: "absolute",
                top: $(window).height() - $element.outerHeight()
            });
        }

        function link($scope, $element, attrs) {
            resizeElement($element);
            angular.element($window).bind('resize', function(){
                resizeElement($element);
            });
        }

        return {
            restrict: 'A',
            scope: {},
            link: link
        };
    }


})();
