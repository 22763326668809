(function() {
    "use strict";

    angular
        .module("pledgemasterApp")
        .controller("PledgingController", PledgingController);

    PledgingController.$inject = [
        "$scope",
        "Principal",
        "LoginService",
        "$state",
        "Auth",
        "RegisterService"
    ];

    function PledgingController(
        $scope,
        Principal,
        LoginService,
        $state,
        Auth,
        RegisterService
    ) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.account = null;
        vm.isAuthenticated = null;

        // TODO maybe make this watch window size changes
        if ($(document).width() < 768) {
            vm.isMobile = true;
        } else {
            vm.isMobile = false;
        }

        vm.propertyCriteriaCarouselActive = 0;
        vm.propertyProcessCarouselActive = 0;
        vm.roomCriteriaCarouselActive = 0;
        vm.roomProcessCarouselActive = 0;
        vm.login = LoginService.open;
        vm.registerModal = registerModal;
        vm.registerAccount = {};
        vm.success = null;
        vm.register = register;
        vm.showInfo = false;
        vm.moreInfo = moreInfo;
        vm.lessmore = "More";

        vm.profile = profile;
        vm.pledgeToday = pledgeToday;

        // Page data
        vm.content = {
            property: {
                criteria: [
                    {
                        text:
                            "Vacant properties can be apartments, houses and converted accommodation in which no one else is living",
                        icon: "house-building"
                    },
                    {
                        text:
                            "Vacant properties should have at least one bedroom",
                        icon: "room-no-1"
                    },
                    {
                        text:
                            "Vacant properties can be offered by the following: private landlords, people with second homes, businesses and letting agents, charities, community groups",
                        icon: "people"
                    },
                    {
                        text:
                            "For accommodation pledges we request a minimum of 6–12 months commitment",
                        icon: "calendar"
                    },
                    {
                        text:
                            "Where possible, it is preferable that properties should be close to public transport and within a short commute to villages, towns or cities",
                        icon: "train"
                    },
                    {
                        text:
                            "Where possible it is preferable that properties are accessible to banks, post office, shops, English language courses, schools, crèches and employment opportunities.",
                        icon: "bank"
                    },
                    {
                        text:
                            "All properties must be in a reasonable condition",
                        icon: "clipboard-checkmark"
                    }
                ],
                process: [
                    "You offer your property through the website or by contacting the IRC / Local Authority for your property.",
                    "The IRC / Consortium / Local Authority will contact you to confirm some details, discuss the process with you and answer any queries you may have.",
                    "If you are happy to proceed, and your property is suitable, the Local Authority, Engineers or Auctioneers Ireland will arrange to assess the property. This is because properties must meet certain minimum standards and the assessment also helps to ensure the IRC / Local Authority can find the best match for the property.",
                    "The IRC / Consortium / Local Authority will let you know if the property meets the required standard.If the property meets the required standards, the Local Authority will begin to work to match the property to the most suitable people in need of accommodation.If the property does not meet the standards, the Local Authority or IRC will give you further information on what the issues were and how they might be addressed and there is some funding available to assist with this if necessary.",
                    "When a suitable Ukrainian person or family has been identified and has agreed that they wish to take up the offer, the Local Authority or IRC or Consortium  will notify you and they will facilitate contact between you and the beneficiary.The IRC / Consortium  / Local Authority will also provide you and the beneficiary with a template license agreement, which you can choose to adapt or sign with the beneficiary before they move in. The IRC will also provide a memorandum of understanding with you.",
                    "Throughout the arrangement, the IRC / Consortium / Local Authority will maintain contact with you and with the Ukrainian beneficiary and offer support if necessary.When it comes to the end of the arrangement, the Local Authority or IRC or Consortium can support the Ukrainian person or family to find alternative accommodation through other offered properties or through the Department of Children, Equality, Disability, Integration and Youth.IRC and the Local Authorities are both working with a Consortium which include International Organization for Migration (IOM), Helping Irish Hosts (HIH) and The Peter McVerry Trust (PMVT).",
                    "All of the above applies to all refugees from a variety of nationalities who are being accommodated by the Irish Red Cross pledge system."
                ]
            },
            room: {
                criteria: [
                    {
                        text:
                            "Shared properties are those with at least one single spare room",
                        icon: "room-no-1"
                    },
                    {
                        text:
                            "For pledges of property, we request a minimum of 6-12 months commitment",
                        icon: "calendar"
                    },
                    {
                        text:
                            "Shared properties must include direct access to cooking facilitates, shared or private bathrooms, and private bedrooms of a reasonable size",
                        icon: "clipboard-checkmark"
                    },
                    {
                        text:
                            "Where possible, it is preferable that properties should be close to public transport and within a short commute to villages, towns or cities",
                        icon: "train"
                    }/*,
                    {
                        text:
                            "Housing must be in reasonably close proximity to banks, a post office, shops and English language courses, schools, cr\xE8ches and employment opportunities",
                        icon: "bank"
                    }*/
                ],
                process: [
                "You will receive an acknowledgement call to check the details of your offer over the phone.",
                "You will be asked if you are open to accommodate a parent or family with children under 16.   If you answer yes to this, you and your family will be required to be Garda Vetted. https://vetting.garda.ie/ The process involved in Garda Vetting involves an ID verification online meeting to verify that the pledger is who they say they are. Following this meeting, the completion of statutory Garda Vetting forms will be required, these forms will be sent to you to complete and for you to send to the Garda Vetting Bureau for analysis.",
                "Following that a Caseworker will work to match your offer to person(s) newly arrived in Ireland. When the Caseworker is matching the aim is to find matches that best benefit hosts, host communities and the people we are supporting. Photographs of the accommodation may be required at this stage",
                "If a match is made, with your permission, we will arrange a meeting with you, a Caseworker and the matched person(s). This may happen in-person or on-line, at your convenience.",
                "If all parties are happy to proceed, we will arrange a move date.",
                "We support with the move both practically and socially, and your dedicated Red Cross or Consortium Caseworker will be in regular contact to support you, your community and the people using our service. Consortium: Irish Red Cross, International Organisation for Migration, Peter McVerry Trust and Helping Irish Hosts"
                ]
            }
        };

        // window.scrollTo(0, 0);

        function profile() {
            $state.go("dashboard-prepop");
        }

        function moreInfo() {
            if (vm.showInfo) {
                vm.showInfo = false;
                vm.lessmore = "More";
            } else {
                vm.showInfo = true;
                vm.lessmore = "Less";
            }
        }

        // if ($(document).width() > 700) {
        //     $('.butts').css("width", "25%");
        //     $('.butts').css("margin-bottom", "0");
        //     // $('.help_login').css("margin-bottom", "25px");
        // }

        $scope.$on("authenticationSuccess", function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function registerModal() {
            // TODO get correct ga path
            // ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/open-modal/home/header/" });
            RegisterService.open();
        }
        function pledgeToday() {
            if (vm.isAuthenticated()) {
                $state.go("dashboard-prepop");
            } else {
                registerModal();
            }
        }
        // The top register area
        function register () {
            //if (vm.$state.includes('admin'))
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/click/home/module2"});
           // else ga('send', 'pageView', "/virtual/register/click/home/module2");

            vm.registerAccount.langKey = 'en';
            vm.registerAccount.login = vm.registerAccount.email;
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;

            Auth.createAccount(vm.registerAccount).then(function () {
                vm.success = 'OK';
                Auth.login({
                    username: vm.registerAccount.email,
                    password: vm.registerAccount.password,
                    rememberMe: false
                }).then(function () {
                    vm.authenticationError = false;
                    //$uibModalInstance.close();
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('dashboard-prepop');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                }).catch(function () {
                    $state.go("dashboard-prepop")
                    vm.authenticationError = true;
                });
            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                } else if (response.status === 400 && response.data === 'email address already in use') {
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            });

        }
    }
})();
