(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeServicesController', PledgeServicesController);

    PledgeServicesController.$inject = ['DataUtils', 'PledgeServices', 'PledgeServicesSearch'];

    function PledgeServicesController(DataUtils, PledgeServices, PledgeServicesSearch) {

        var vm = this;

        vm.pledgeServices = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        //vm.filteredList = {};
        vm.filteredSearch = {}

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};

        vm.createdAfter = "";
        vm.createdBefore = "";
        vm.availableFrom = "";
        vm.availableUntil = "";
        vm.updatedAfter = "";
        vm.updatedBefore = "";

        vm.csv = csv;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function csv(){
            console.log(convertArrayOfObjectsToCSV(vm.filteredSearch))
        }

        function convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args || null;
            if (data == null || !data.length) {
                return null;
            }

            var deepDiveColumns = ["type","owner","condition","bedrooms","occupancies","canYouAccoms"];

            var columns = ["county", "name", "phone", "email", "serviceAdditionalInfo"]


            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(data[0]);

            var date = new Date();

            result = "PLEDGED SERVICES REPORT - " + date.getDate().toString() + " " + date.getMonthName() + " " + date.getFullYear();
            result += lineDelimiter;
            result += lineDelimiter;
            //result += vm.reportText.replace("<b>", "").replace("</b>","").replace("<br>","using the filters ") + '\n\n';
            result += columns.join(columnDelimiter);
            result += lineDelimiter;
            result += lineDelimiter;

            data.forEach(function(item) {
                ctr = 0;
                columns.forEach(function(key) {
                    if (ctr > 0) result += columnDelimiter;

                    // if (deepDiveColumns.includes(key)) {
                    //     // item[k].forEach(function())
                    //     if (item[key]) result += item[key];
                    //     ctr++;
                    // }
                    // else {
                    if (item[key]) {
                        if (key == "bedrooms"){
                            var beds = item[key].amount;
                            result += beds.replace(/,/g, " ").replace(/\n/g, " ");
                        }
                        else if (key == "occupancies"){
                            var occupancy = item[key];
                            if (occupancy[0]) result += occupancy[0].accommsOccupancy.replace(/,/g, " ").replace(/\n/g, " ");
                            for (var i = 1; i < occupancy.length; i++){
                                result += " & " + occupancy[0].accommsOccupancy.replace(/,/g, " ").replace(/\n/g, " ");
                            }
                        }
                        else{
                            result += item[key].replace(/,/g, " ").replace(/\n/g, " ");
                        }

                    }
                    else{
                        result += " ";
                    }
                    ctr++;
                    // }
                });
                result += lineDelimiter;
            });

            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(result);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'ServicesReport - ' + date.getDate().toString() + " " + date.getMonthName() + " " + date.getFullYear() + '.csv';
            hiddenElement.click();

            //return result;
        }

        vm.advancedSearch = advancedSearch;
        vm.advancedSearchModel = {};
        loadAll();

        function loadAll() {
            PledgeServices.query(function(result) {
                vm.pledgeServices = result;
                vm.searchQuery = null;
                vm.filteredSearch = vm.pledgeServices;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PledgeServicesSearch.query({query: vm.searchQuery}, function(result) {
                vm.pledgeServices = result;
                vm.currentSearch = vm.searchQuery;
                vm.filteredList = vm.pledgeServices;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }

        function name(filteredList){
            return filteredList.filter(function(entry) {

                if (!entry.name) return false;
                return entry.name.toLowerCase().indexOf(vm.advancedSearchModel.name.toLowerCase()) >= 0;
            })
        }

        function phone(filteredList){
            return filteredList.filter(function(entry) {

                if (!entry.phone) return false;
                return entry.phone.toLowerCase().indexOf(vm.advancedSearchModel.phone.toLowerCase()) >= 0;
            })
        }

        function email(filteredList){
            return filteredList.filter(function(entry) {

                if (!entry.email) return false;
                return entry.email.toLowerCase().indexOf(vm.advancedSearchModel.email.toLowerCase()) >= 0;
            })
        }
        function counties(filteredList){
            return filteredList.filter(function(entry) {
                if (!entry.serviceAreaCounty) return false;
                return entry.serviceAreaCounty.toLowerCase().indexOf(vm.advancedSearchModel.county.toLowerCase()) >= 0;
            })
        }
        function serviceOne(filteredList){
            return filteredList.filter(function(entry) {
                if (!entry.serviceListOne) return false;
                return entry.serviceListOne.select.toLowerCase().indexOf(vm.advancedSearchModel.service1.toLowerCase()) >= 0;
            })
        }
        function serviceTwo(filteredList){
            return filteredList.filter(function(entry) {
                if (!entry.serviceListTwo) return false;
                return entry.serviceListTwo.select.toLowerCase().indexOf(vm.advancedSearchModel.service2.toLowerCase()) >= 0;
            })
        }
        function serviceThree(filteredList){
            return filteredList.filter(function(entry) {
                if (!entry.serviceListThree) return false;
                return entry.serviceListThree.select.toLowerCase().indexOf(vm.advancedSearchModel.service3.toLowerCase()) >= 0;
            })
        }

        function filterDateAfter(filteredList, dateFilterType, date){
            return filteredList.filter(function(entry) {
                // CREATED
                if (dateFilterType == "createdAfter"){
                    var savedDate = new Date(entry.created).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "createdBefore"){


                    var savedDate = new Date(entry.created).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate <= date;
                }
                else if (dateFilterType == "availableFrom"){
                    //AVAILABLE


                    var savedDate = new Date(entry.accommodationStartDate).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "availableUntil"){


                    var savedDate = new Date(entry.accommodationEndDate).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "updatedAfter"){
                    //UPDATED


                    var savedDate = new Date(entry.lastUpdated).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "updatedBefore"){


                    var savedDate = new Date(entry.lastUpdated).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate <= date;
                }
            })
        }

        function advancedSearch(funk){
            vm.reportText = "";
            vm.filteredSearch = vm.pledgeServices;
            if (vm.advancedSearchModel.service1) vm.filteredSearch = serviceOne(vm.filteredSearch);
            if (vm.advancedSearchModel.service2) vm.filteredSearch = serviceTwo(vm.filteredSearch);
            if (vm.advancedSearchModel.service3) vm.filteredSearch = serviceThree(vm.filteredSearch);
            if (vm.advancedSearchModel.email) vm.filteredSearch = email(vm.filteredSearch);

            // if (vm.advancedSearchModel.firstName) vm.filteredSearch = firstName(vm.filteredSearch);
            // if (vm.advancedSearchModel.surname) vm.filteredSearch = surname(vm.filteredSearch);

            if (vm.advancedSearchModel.county) vm.filteredSearch = counties(vm.filteredSearch);
            if (vm.advancedSearchModel.phone) vm.filteredSearch = phone(vm.filteredSearch);
            if (vm.advancedSearchModel.name) vm.filteredSearch = name(vm.filteredSearch);
            //
            // if (vm.advancedSearchModel.date) vm.filteredSearch = date(vm.filteredSearch);

            // DATES
            if (vm.createdAfter){
                var dateString = new Date(vm.createdAfter).toString("dd/MM/yyyy");
                //var dateString = vm.createdAfter;
                var dateAfter = new Date(vm.createdAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;
                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "createdAfter", date)
                vm.reportText += "<br> - Created After: " + dateString;

            }
            if (vm.createdBefore) {
                var dateString = new Date(vm.createdBefore).toString("dd/MM/yyyy");
                // var dateString = vm.createdBefore;
                var dateAfter = new Date(vm.createdBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "createdBefore", date);
                vm.reportText += "<br> - Created Before: " + dateString;

            }
            if (vm.availableAfter) {
                var dateString = new Date(vm.availableAfter).toString("dd/MM/yyyy");
                // var dateString = vm.availableAfter;
                var dateAfter = new Date(vm.availableAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "availableFrom",date);
                vm.reportText += "<br> - Available From: " + dateString;
            }
            if (vm.availableBefore) {
                var dateString = new Date(vm.availableBefore).toString("dd/MM/yyyy");
                //   var dateString = vm.availableBefore;
                var dateAfter = new Date(vm.availableBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "availableUntil");

                vm.reportText += "<br> - Available Until: " + dateString;

            }
            if (vm.updatedAfter) {
                var dateString = new Date(vm.updatedAfter).toString("dd/MM/yyyy");
                //  var dateString = vm.updatedAfter;
                var dateAfter = new Date(vm.updatedAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "updatedAfter", date);
                vm.reportText += "<br> - Updated After: " + dateString;

            }
            if (vm.updatedBefore) {
                var dateString = new Date(vm.updatedBefore).toString("dd/MM/yyyy");
                var dateAfter = new Date(vm.updatedBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "updatedBefore");
                vm.reportText += "<br> - Updated Before: " + dateString;
            }

            if (vm.advancedSearchModel.status) vm.filteredSearch = status(vm.filteredSearch);

            if (vm.advancedSearchModel.matched) vm.filteredSearch = matched(vm.filteredSearch);
            if (vm.advancedSearchModel.recordedBy) vm.filteredSearch = recordedBy(vm.filteredSearch);

            if (vm.advancedSearchModel.restrictions) vm.filteredSearch = restrictions(vm.filteredSearch);
            if (vm.advancedSearchModel.status) vm.filteredSearch = status(vm.filteredSearch);

            vm.reportText = "<b>" + vm.filteredSearch.length + " results </b> with search term \"" + vm.currentSearch + "\" " + vm.reportText;
        }

    }
})();
