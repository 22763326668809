(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('information', {
            parent: 'app',
            url: '/information',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/information/information.html',
                    controller: 'InformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('information');
                    return $translate.refresh();
                }]
            }
        }).state('information-housing', {
            parent: 'app',
            url: '/information/housing',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/information/housing.html',
                    controller: 'InformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('information-housing');
                    return $translate.refresh();
                }]
            }
        }).state('information-services', {
            parent: 'app',
            url: '/information/services',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/information/services.html',
                    controller: 'InformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('information-services');
                    return $translate.refresh();
                }]
            }
        }).state('information-goods', {
            parent: 'app',
            url: '/information/goods',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/information/goods.html',
                    controller: 'InformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('information-goods');
                    return $translate.refresh();
                }]
            }
        }).state('information-share', {
            parent: 'app',
            url: '/information/share',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/information/pledgeroom.html',
                    controller: 'InformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('information-share');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
