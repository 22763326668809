(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommsFacilitiesDetailController', AccommsFacilitiesDetailController);

    AccommsFacilitiesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccommsFacilities'];

    function AccommsFacilitiesDetailController($scope, $rootScope, $stateParams, previousState, entity, AccommsFacilities) {
        var vm = this;

        vm.accommsFacilities = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:accommsFacilitiesUpdate', function(event, result) {
            vm.accommsFacilities = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
