(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('GoodsCategoryOneDetailController', GoodsCategoryOneDetailController);

    GoodsCategoryOneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GoodsCategoryOne'];

    function GoodsCategoryOneDetailController($scope, $rootScope, $stateParams, previousState, entity, GoodsCategoryOne) {
        var vm = this;

        vm.goodsCategoryOne = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:goodsCategoryOneUpdate', function(event, result) {
            vm.goodsCategoryOne = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
