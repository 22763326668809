(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeScreeningDetailController', PledgeScreeningDetailController);

    PledgeScreeningDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PledgeScreening', 'RedcrossScreeningOneDoneBy', 'RedcrossScreeningOneStatus', 'RedcrossScreeningTwoDoneBy', 'RedcrossScreeningTwoAssessmentStatus', 'RedcrossStatus', 'RedcrossRejectedReason'];

    function PledgeScreeningDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PledgeScreening, RedcrossScreeningOneDoneBy, RedcrossScreeningOneStatus, RedcrossScreeningTwoDoneBy, RedcrossScreeningTwoAssessmentStatus, RedcrossStatus, RedcrossRejectedReason) {
        var vm = this;

        vm.pledgeScreening = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('pledgemasterApp:pledgeScreeningUpdate', function(event, result) {
            vm.pledgeScreening = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
