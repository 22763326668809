(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('TownsController', TownsController);

    TownsController.$inject = ['Towns', 'TownsSearch'];

    function TownsController(Towns, TownsSearch) {

        var vm = this;

        vm.towns = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Towns.query(function(result) {
                vm.towns = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            TownsSearch.query({query: vm.searchQuery}, function(result) {
                vm.towns = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
