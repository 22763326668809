(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .directive('offCanvasMenu', offCanvasMenu)

    function offCanvasMenu() {
        return {
            restrict: 'A',
            replace: false,
            link: function (scope, element) {
                scope.isMenuOpen = false;
                scope.toggleMenu = function () {
                    scope.isMenuOpen = !scope.isMenuOpen;
                };
                scope.closeMenu = function () {
                    scope.isMenuOpen = false;
                };
                scope.openMenu = function () {
                    scope.isMenuOpen = true;
                };
            }
        };
    }


})();
