(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AssessedByDetailController', AssessedByDetailController);

    AssessedByDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AssessedBy'];

    function AssessedByDetailController($scope, $rootScope, $stateParams, previousState, entity, AssessedBy) {
        var vm = this;

        vm.assessedBy = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:assessedByUpdate', function(event, result) {
            vm.assessedBy = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
