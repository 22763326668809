(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('HousingReportsController', HousingReportsController);

    HousingReportsController.$inject = ['HousingReports', 'HousingReportsSearch', 'Property', 'AccommodationType', 'NumberOfBedRooms', 'Duration', 'Towns', 'Counties', 'Restrictions', 'AssessedBy', 'DateOfAssessment', 'Status', 'Matched', '$sce'];

    function HousingReportsController(HousingReports, HousingReportsSearch,  Property, AccommodationType, NumberOfBedRooms, Duration, Towns, Counties, Restrictions, AssessedBy, DateOfAssessment, Status, Matched, $sce) {

        var vm = this;

        vm.housingReports = [];
        vm.fullHousingReportsModel = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.properties = Property.query();
        vm.accommodationtypes = AccommodationType.query();
        vm.numberofbedrooms = NumberOfBedRooms.query();
        vm.durations = Duration.query();
        vm.towns = Towns.query();
        vm.counties = Counties.query();
        vm.restrictions = Restrictions.query();
        vm.assessedbies = AssessedBy.query();
        vm.dateofassessments = DateOfAssessment.query();
        vm.statuses = Status.query();
        vm.matcheds = Matched.query();

        vm.reportText = "";

        vm.advancedSearchModel = {};
        vm.advancedSearch = advancedSearch;

        vm.sanitize = sanitize;

        function sanitize (str){
            return $sce.trustAsHtml(str);
        }

        loadAll();

        function recordedBy(filteredList){
            vm.reportText += "<br> - Recorded By: " + vm.advancedSearchModel.recordedBy;
            return filteredList.filter(function(entry) {
                return entry.recordedBy.toLowerCase().indexOf(vm.advancedSearchModel.recordedBy) >= 0;
            })
        }
        function restrictions(filteredList){
            vm.reportText += "<br> - Restrictions: " + vm.advancedSearchModel.restrictions;
            return filteredList.filter(function(entry) {
                return entry.restrictions.restrictions.toLowerCase().indexOf(vm.advancedSearchModel.restrictions) >= 0;
            })
        }
        function duration(filteredList){
            vm.reportText += "<br> - Duration: " + vm.advancedSearchModel.duration;
            return filteredList.filter(function(entry) {
                return entry.duration.duration.toLowerCase().indexOf(vm.advancedSearchModel.duration) >= 0;
            })
        }

        function propertyStatus(filteredList){
            vm.reportText += "<br> - Property: " + vm.advancedSearchModel.property;
            return filteredList.filter(function(entry) {
                return entry.propertyStatus.status.toLowerCase().indexOf(vm.advancedSearchModel.property) >= 0;
            })
        }
        function accommodationType(filteredList){
            vm.reportText += "<br> - Accommodation Type: " + vm.advancedSearchModel.type;
            return filteredList.filter(function(entry) {
                return entry.accommodationType.type.toLowerCase().indexOf(vm.advancedSearchModel.type) >= 0;
            })
        }
        function rooms(filteredList){
            vm.reportText += "<br> - Number of Rooms: " + vm.advancedSearchModel.rooms;
            return filteredList.filter(function(entry) {
                console.log("rooms", entry);
                return entry.numberOfBedRooms.number.toString().toLowerCase().indexOf(vm.advancedSearchModel.rooms) >= 0;
            })
        }
        function towns(filteredList){
            vm.reportText += "<br> - Town: " + vm.advancedSearchModel.towns;
            return filteredList.filter(function(entry) {
                return entry.towns.towns.toLowerCase().indexOf(vm.advancedSearchModel.towns) >= 0;
            })
        }
        function counties(filteredList){
            vm.reportText += "<br> - Recrded By: " + vm.advancedSearchModel.county;
            return filteredList.filter(function(entry) {
                return entry.counties.counties.toLowerCase().indexOf(vm.advancedSearchModel.county) >= 0;
            })
        }
        function assessed(filteredList){
            vm.reportText += "<br> - Assessed By: " + vm.advancedSearchModel.assessed;
            return filteredList.filter(function(entry) {
                return entry.assessedBy.assessedBy.toLowerCase().indexOf(vm.advancedSearchModel.assessed) >= 0;
            })
        }
        function date(filteredList){
            vm.reportText += "<br> - Date Assessed: " + vm.advancedSearchModel.date;
            // if date contains special range characters then split into two dates, convert to timestamps and filter return all within raange

            var dates = [];
            var dateQuery = vm.advancedSearchModel.date;
            if (dateQuery.indexOf(" - ") >= 0 ) dates = dateQuery.split(" - ");
            else if (dateQuery.indexOf(" to ") >= 0 ) dates = dateQuery.split(" to ");

            if (dates.length > 1){
                dates[0] = Date.parse(dates[0]).getTime()/1000;
                dates[1] = Date.parse(dates[1]).getTime()/1000;

                return filteredList.filter(function(entry) {
                    console.log("date", entry.dateOfAssessment);
                    var savedDate = new Date(entry.dateOfAssessment).toString("dd/MM/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;

                    return  savedDate >= dates[0] && savedDate <= dates[1];
                })
            }
            else{
                return filteredList.filter(function(entry) {
                    console.log("date", entry.dateOfAssessment);
                    var savedDate = new Date(entry.dateOfAssessment).toString("dd/MM/yyyy");
                    var savedDateShort = new Date(entry.dateOfAssessment).toString("d/M/yyyy");

                    if (savedDate.toLowerCase().indexOf(vm.advancedSearchModel.date) >= 0) return true;
                    else return savedDateShort.toLowerCase().indexOf(vm.advancedSearchModel.date) >= 0;
                })
            }
        }
        function status(filteredList){
            vm.reportText += "<br> - Status: " + vm.advancedSearchModel.status;
            return filteredList.filter(function(entry) {
                return entry.status.status.toLowerCase().indexOf(vm.advancedSearchModel.status) >= 0;
            })
        }
        function matched(filteredList){
            vm.reportText += "<br> - Matched: " + vm.advancedSearchModel.matched;
            return filteredList.filter(function(entry) {
                return entry.matched.matched.toLowerCase().indexOf(vm.advancedSearchModel.matched) >= 0;
            })
        }

        function advancedSearch(){
            vm.reportText = "";
            vm.housingReports = vm.fullHousingReportsModel;
            if (vm.advancedSearchModel.property !== undefined) vm.housingReports = propertyStatus(vm.housingReports);
            if (vm.advancedSearchModel.type !== undefined) vm.housingReports = accommodationType(vm.housingReports);

            if (vm.advancedSearchModel.rooms !== undefined) vm.housingReports = rooms(vm.housingReports);
            if (vm.advancedSearchModel.towns !== undefined) vm.housingReports = towns(vm.housingReports);

            if (vm.advancedSearchModel.county !== undefined) vm.housingReports = counties(vm.housingReports);
            if (vm.advancedSearchModel.assessed !== undefined) vm.housingReports = assessed(vm.housingReports);

            if (vm.advancedSearchModel.date !== undefined) vm.housingReports = date(vm.housingReports);
            if (vm.advancedSearchModel.status !== undefined) vm.housingReports = status(vm.housingReports);

            if (vm.advancedSearchModel.matched !== undefined) vm.housingReports = matched(vm.housingReports);
            if (vm.advancedSearchModel.recordedBy !== undefined) vm.housingReports = recordedBy(vm.housingReports);

            if (vm.advancedSearchModel.restrictions !== undefined) vm.housingReports = restrictions(vm.housingReports);
            if (vm.advancedSearchModel.duration !== undefined) vm.housingReports = duration(vm.housingReports);

            vm.reportText = "<b>" + vm.housingReports.length + " results </b> for " + vm.reportText;
        }

        function loadAll() {
            HousingReports.query(function(result) {
                vm.housingReports = result;
                vm.fullHousingReportsModel = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            HousingReportsSearch.query({query: vm.searchQuery}, function(result) {
                vm.housingReports = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
