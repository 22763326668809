(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PropertyController', PropertyController);

    PropertyController.$inject = ['Property', 'PropertySearch'];

    function PropertyController(Property, PropertySearch) {

        var vm = this;

        vm.properties = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Property.query(function(result) {
                vm.properties = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PropertySearch.query({query: vm.searchQuery}, function(result) {
                vm.properties = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
