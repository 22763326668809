(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('about', {
            parent: 'app',
            url: '/about',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/about/about.html',
                    controller: 'AboutController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('about');
                    return $translate.refresh();
                }]
            }
        }).state('about-prepop', {
            parent: 'app',
            url: '/about/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/about/about.html',
                    controller: 'AboutController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('about');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                    var zz = UserProfileById.get({id : 0}).$promise;
                    return zz;
                }],
                pledgeGoods: ['$stateParams', 'PledgeGoods', function($stateParams, PledgeGoods) {
                    return PledgeGoods.query(function(result) {

                    });
                }],
                pledgeServices: ['$stateParams', 'PledgeServices', function($stateParams, PledgeServices) {
                    return PledgeServices.query(function(result) {

                    });
                }],
                pledgeAccommodations: ['$stateParams', 'PledgeAccommodations', function($stateParams, PledgeAccommodations) {
                    return PledgeAccommodations.query(function(result) {

                    });
                }]
            }
        }).state('about-profile-edit', {
            parent: 'app',
            url: '/about/profile/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/about/user-profile-dialog.html',
                    controller: 'AboutUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 0}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('about-prepop', null, { reload: 'about-prepop' });
                }, function() {
                    $state.go('about-prepop');
                });
            }]
        }).state('about-profile-new', {
            parent: 'app',
            url: '/about/profile/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/about/user-profile-dialog.html',
                    controller: 'AboutUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firstName: null,
                                lastName: null,
                                phoneNumber: null,
                                profileAddress1: null,
                                profileAddress2: null,
                                profileAddress3: null,
                                profileAddressCityTownVillage: null,
                                profileAddressArea: null,
                                profileAddressCountry: null,
                                profileAddressAreaCode: null,
                                organisationFlag: null,
                                organisationName: null,
                                redcrossVettedId: null,
                                redcrossVettedExpDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                }, function() {
                    $state.go('dashboard-prepop');
                });
            }]
        })
    }
})();
