(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeServiceController', PledgeServiceController);

    PledgeServiceController.$inject = ['PledgeService', 'PledgeServiceSearch'];

    function PledgeServiceController(PledgeService, PledgeServiceSearch) {

        var vm = this;

        vm.pledgeServices = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PledgeService.query(function(result) {
                vm.pledgeServices = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PledgeServiceSearch.query({query: vm.searchQuery}, function(result) {
                vm.pledgeServices = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
