(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AboutController', AboutController);

    AboutController.$inject = ['$location', 'UserProfile', '$scope', 'Principal', 'LoginService', '$state', 'Auth', 'entity', 'pledgeGoods', 'pledgeAccommodations', 'pledgeServices'];

    function AboutController ($location, UserProfile, $scope, Principal, LoginService, $state, Auth, entity, pledgeGoods, pledgeAccommodations, pledgeServices) {
        var vm = this;


        vm.save = save;
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerPage = registerPage;
        vm.registerAccount = {};
        vm.success = null;
        vm.names = ["Yes", "No"];

        vm.userProfile = entity;
        vm.pledgedGoods = pledgeGoods;
        vm.pledgedServices = pledgeServices;
        vm.pledgedAccommodations = pledgeAccommodations;


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        $scope.gotoBottom = function() {
            // set the location.hash to the id of
            // the element you wish to scroll to.
            $location.hash('terms');

            // call $anchorScroll()
           // $anchorScroll();
        };

        function save(){
            vm.isSaving = true;
            if (vm.userProfile.id !== null) {
                UserProfile.update(vm.userProfile, onSaveSuccess, onSaveError);
            } else {
                UserProfile.save(vm.userProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:userProfileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function registerPage () {
            $state.go('register');
        }
        function register () {

                vm.registerAccount.langKey = 'en';
                vm.registerAccount.login = vm.registerAccount.email;
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;

                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.success = 'OK';
                    Auth.login({
                        username: vm.registerAccount.login,
                        password: vm.registerAccount.password,
                        rememberMe: false
                    }).then(function () {
                        //vm.authenticationError = false;
                        //$uibModalInstance.close();
                        if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                            $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                            $state.go('verification');
                        }

                        $rootScope.$broadcast('authenticationSuccess');

                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        if (Auth.getPreviousState()) {
                            var previousState = Auth.getPreviousState();
                            Auth.resetPreviousState();
                            $state.go(previousState.name, previousState.params);
                        }
                    }).catch(function () {
                        vm.authenticationError = true;
                    });
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });

        }
    }
})();
