(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommodationFacilitiesController', AccommodationFacilitiesController);

    AccommodationFacilitiesController.$inject = ['AccommodationFacilities', 'AccommodationFacilitiesSearch'];

    function AccommodationFacilitiesController(AccommodationFacilities, AccommodationFacilitiesSearch) {

        var vm = this;

        vm.accommodationFacilities = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            AccommodationFacilities.query(function(result) {
                vm.accommodationFacilities = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            AccommodationFacilitiesSearch.query({query: vm.searchQuery}, function(result) {
                vm.accommodationFacilities = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
