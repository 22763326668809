(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('accomms-facilities', {
            parent: 'entity',
            url: '/accomms-facilities',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pledgemasterApp.accommsFacilities.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accomms-facilities/accomms-facilities.html',
                    controller: 'AccommsFacilitiesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accommsFacilities');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('accomms-facilities-detail', {
            parent: 'accomms-facilities',
            url: '/accomms-facilities/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pledgemasterApp.accommsFacilities.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accomms-facilities/accomms-facilities-detail.html',
                    controller: 'AccommsFacilitiesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accommsFacilities');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AccommsFacilities', function($stateParams, AccommsFacilities) {
                    return AccommsFacilities.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'accomms-facilities',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('accomms-facilities-detail.edit', {
            parent: 'accomms-facilities-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accomms-facilities/accomms-facilities-dialog.html',
                    controller: 'AccommsFacilitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AccommsFacilities', function(AccommsFacilities) {
                            return AccommsFacilities.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('accomms-facilities.new', {
            parent: 'accomms-facilities',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accomms-facilities/accomms-facilities-dialog.html',
                    controller: 'AccommsFacilitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                accommsFacilities: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('accomms-facilities', null, { reload: 'accomms-facilities' });
                }, function() {
                    $state.go('accomms-facilities');
                });
            }]
        })
        .state('accomms-facilities.edit', {
            parent: 'accomms-facilities',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accomms-facilities/accomms-facilities-dialog.html',
                    controller: 'AccommsFacilitiesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AccommsFacilities', function(AccommsFacilities) {
                            return AccommsFacilities.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('accomms-facilities', null, { reload: 'accomms-facilities' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('accomms-facilities.delete', {
            parent: 'accomms-facilities',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accomms-facilities/accomms-facilities-delete-dialog.html',
                    controller: 'AccommsFacilitiesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AccommsFacilities', function(AccommsFacilities) {
                            return AccommsFacilities.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('accomms-facilities', null, { reload: 'accomms-facilities' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
