(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossRejectedReasonDetailController', RedcrossRejectedReasonDetailController);

    RedcrossRejectedReasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RedcrossRejectedReason', 'PledgeScreening'];

    function RedcrossRejectedReasonDetailController($scope, $rootScope, $stateParams, previousState, entity, RedcrossRejectedReason, PledgeScreening) {
        var vm = this;

        vm.redcrossRejectedReason = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:redcrossRejectedReasonUpdate', function(event, result) {
            vm.redcrossRejectedReason = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
