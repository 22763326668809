(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeScreeningDialogController', PledgeScreeningDialogController);

    PledgeScreeningDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'PledgeScreening', 'RedcrossScreeningOneDoneBy', 'RedcrossScreeningOneStatus', 'RedcrossScreeningTwoDoneBy', 'RedcrossScreeningTwoAssessmentStatus', 'RedcrossStatus', 'RedcrossRejectedReason'];

    function PledgeScreeningDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, PledgeScreening, RedcrossScreeningOneDoneBy, RedcrossScreeningOneStatus, RedcrossScreeningTwoDoneBy, RedcrossScreeningTwoAssessmentStatus, RedcrossStatus, RedcrossRejectedReason) {
        var vm = this;

        vm.pledgeScreening = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.redcrossscreeningonedonebies = RedcrossScreeningOneDoneBy.query();
        vm.redcrossscreeningonestatuses = RedcrossScreeningOneStatus.query();
        vm.redcrossscreeningtwodonebies = RedcrossScreeningTwoDoneBy.query();
        vm.redcrossscreeningtwoassessmentstatuses = RedcrossScreeningTwoAssessmentStatus.query();
        vm.redcrossstatuses = RedcrossStatus.query();
        vm.redcrossrejectedreasons = RedcrossRejectedReason.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.pledgeScreening.id !== null) {
                PledgeScreening.update(vm.pledgeScreening, onSaveSuccess, onSaveError);
            } else {
                PledgeScreening.save(vm.pledgeScreening, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:pledgeScreeningUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.redcrossScreenOneDate = false;
        vm.datePickerOpenStatus.redcrossScreenTwoDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
