(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('HousingReportsDialogController', HousingReportsDialogController);

    HousingReportsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HousingReports', 'Property', 'AccommodationType', 'NumberOfBedRooms', 'Duration', 'Towns', 'Counties', 'Restrictions', 'AssessedBy', 'DateOfAssessment', 'Status', 'Matched'];

    function HousingReportsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, HousingReports, Property, AccommodationType, NumberOfBedRooms, Duration, Towns, Counties, Restrictions, AssessedBy, DateOfAssessment, Status, Matched) {
        var vm = this;

        vm.housingReports = entity;
        vm.clear = clear;
        vm.save = save;
        vm.properties = Property.query();
        vm.accommodationtypes = AccommodationType.query();
        vm.numberofbedrooms = NumberOfBedRooms.query();
        vm.durations = Duration.query();
        vm.towns = Towns.query();
        vm.counties = Counties.query();
        vm.restrictions = Restrictions.query();
        vm.assessedbies = AssessedBy.query();
        vm.dateofassessments = DateOfAssessment.query();
        vm.statuses = Status.query();
        vm.matcheds = Matched.query();

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.housingReports.id !== null) {
                HousingReports.update(vm.housingReports, onSaveSuccess, onSaveError);
            } else {
                HousingReports.save(vm.housingReports, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:housingReportsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
