(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AboutUserProfileDialogController', AboutUserProfileDialogController);

    AboutUserProfileDialogController.$inject = ['$uibModalInstance', '$state', '$timeout', '$scope', '$stateParams', '$q', 'entity', 'UserProfile', 'User', 'VettedStatus', 'LanguageList'];

    function AboutUserProfileDialogController ($uibModalInstance, $state, $timeout, $scope, $stateParams, $q, entity, UserProfile, User, VettedStatus, LanguageList) {
        var vm = this;

        vm.userProfile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.vettedstatuses = VettedStatus.query();
        vm.languagelists = LanguageList.query();
        vm.isOrganization = isOrganization;
        vm.isOrganizationFlag = false;

        vm.showInfo = false;
        vm.moreInfo = moreInfo;
        vm.lessmore = "More";

        function moreInfo(){
            if (vm.showInfo) {
                vm.showInfo =false;
                vm.lessmore = "More";
            }
            else {
                vm.showInfo = true;
                vm.lessmore = "Less";
            }
        }

        vm.consent = false;

        function isOrganization (){
            if (vm.isOrganizationFlag) vm.isOrganizationFlag = false;
            else vm.isOrganizationFlag = true;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
           // $uibModalInstance.dismiss('cancel');
          //  $state.go('about-prepop');
        }

        function save () {
            vm.isSaving = true;
            if (vm.userProfile.id !== null) {
                UserProfile.update(vm.userProfile, onSaveSuccess, onSaveError);
            } else {
                UserProfile.save(vm.userProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {

            $scope.$emit('pledgemasterApp:userProfileUpdate', result);
            $uibModalInstance.close(result);

            vm.isSaving = false;
            $state.go('dashboard-prepop');

        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.redcrossVettedExpDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
