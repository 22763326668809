(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('LanguageListDetailController', LanguageListDetailController);

    LanguageListDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LanguageList'];

    function LanguageListDetailController($scope, $rootScope, $stateParams, previousState, entity, LanguageList) {
        var vm = this;

        vm.languageList = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:languageListUpdate', function(event, result) {
            vm.languageList = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
