(function() {
    "use strict";

    angular
        .module("pledgemasterApp")
        .controller("NavbarController", NavbarController);

    NavbarController.$inject = [
        "$state",
        "Auth",
        "Principal",
        "ProfileService",
        "LoginService",
        "$uiViewScroll",
        "$scope"
    ];

    function NavbarController(
        $state,
        Auth,
        Principal,
        ProfileService,
        LoginService,
        $uiViewScroll,
        $scope
    ) {
        var vm = this;

        var openTimeout;

        vm.toggleMenu = $scope.toggleMenu;

        vm.isMenuOpen = $scope.isMenuOpen;

        vm.isNavbarCollapsed = !$scope.isMenuOpen;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.information = information;
        vm.scrollToContact = scrollToContact;
        vm.clickAboutProgramme = clickAboutProgramme;
        // Dropdown is expanded for mobile
        // TODO listen for document size changes
        if ($(document).width() < 768) {
            vm.isMobile = true;
            vm.isOpen = true;
        } else {
            vm.isOpen = false;
            vm.isMobile = false;
        }
        vm.onNavExpanded = onNavExpanded;
        vm.onToggleDropdown = onToggleDropdown;

        vm.howItWorksEnter = howItWorksEnter;
        vm.howItWorksExit = howItWorksExit;

        vm.$state = $state;

        checkUserAgent();

        function checkUserAgent() {
            var ua = window.navigator.userAgent.toString().toLowerCase();
            if (
                ua.indexOf("iphone") >= 0 &&
                ua.indexOf("safari") >= 0 &&
                ua.indexOf("chrome") < 0
            ) {
                // $('.container').css("padding-left", "50px");
                // $('.container').css("padding-right", "50px");
            }
        }

        function login() {
            //if (vm.$state.includes('admin'))
            ga("send", "pageView", {
                page:
                    location.pathname +
                    location.search +
                    location.hash +
                    "virtual/login/open-modal/home/header/"
            });
            //else ga('send', 'pageView', "/admin/virtual/login/open-modal/home/header/");
            collapseNavbar();
            LoginService.open();
        }

        function information() {
            $state.go("information");
        }

        function clickAboutProgramme() {
            collapseNavbar();
            $state.go("home").then(function() {
                $uiViewScroll($("#about-programme"));
            });
        }

        function scrollToContact() {
            collapseNavbar();
            $uiViewScroll($("#contact-us"));
        }

        function logout() {
            $scope.setSiteMessage("You are now logged out.");
            collapseNavbar();
            Auth.logout();
            $state.go("home");
        }

        function toggleNavbar() {
            // Make sure to oepn the  submenu
            // vm.isOpen = true;
            // vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            $scope.toggleMenu();
            vm.isNavbarCollapsed = !$scope.isMenuOpen;
        }

        function collapseNavbar() {
            $scope.closeMenu();
            vm.isNavbarCollapsed = !$scope.isMenuOpen;
        }

        function onNavExpanded() {
            // alert('expand');
        }

        function onToggleDropdown(open) {
            // Keep it open if it's mobile
            // if ($(document).width() < 768) {
            //     vm.isOpen = true;
            // }
        }

        function howItWorksEnter() {
            if (vm.isMobile) {
                return;
            }
            // Simple debouncing;
            if (openTimeout) {
                clearTimeout(openTimeout);
            }
            openTimeout = setTimeout(function() {
                console.log("enter");
                $scope.$apply(function() {
                    vm.isOpen = true;
                });
            }, 100);
        }

        function howItWorksExit() {
            if (vm.isMobile) {
                return;
            }
            // Simple debouncing;
            if (openTimeout) {
                clearTimeout(openTimeout);
            }
            openTimeout = setTimeout(function() {
                console.log("Leave");
                $scope.$apply(function() {
                    vm.isOpen = false;
                });
            }, 100);
        }
    }
})();
