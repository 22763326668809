(function() {
    'use strict';
    // An info slider that uses bootstrap carousel
    angular.module('pledgemasterApp').component('infoSlider', {
        templateUrl: 'app/components/info-slider/info-slider.html',
        // bindings: {
        //     info: '='
        // }
    });
})();
