(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommsLocalAmenitiesController', AccommsLocalAmenitiesController);

    AccommsLocalAmenitiesController.$inject = ['AccommsLocalAmenities', 'AccommsLocalAmenitiesSearch'];

    function AccommsLocalAmenitiesController(AccommsLocalAmenities, AccommsLocalAmenitiesSearch) {

        var vm = this;

        vm.accommsLocalAmenities = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            AccommsLocalAmenities.query(function(result) {
                vm.accommsLocalAmenities = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            AccommsLocalAmenitiesSearch.query({query: vm.searchQuery}, function(result) {
                vm.accommsLocalAmenities = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
