(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('AssessedBySearch', AssessedBySearch);

    AssessedBySearch.$inject = ['$resource'];

    function AssessedBySearch($resource) {
        var resourceUrl =  'api/_search/assessed-bies/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
