(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossScreeningOneStatusDeleteController',RedcrossScreeningOneStatusDeleteController);

    RedcrossScreeningOneStatusDeleteController.$inject = ['$uibModalInstance', 'entity', 'RedcrossScreeningOneStatus'];

    function RedcrossScreeningOneStatusDeleteController($uibModalInstance, entity, RedcrossScreeningOneStatus) {
        var vm = this;

        vm.redcrossScreeningOneStatus = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RedcrossScreeningOneStatus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
