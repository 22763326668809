(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommodationOccupancyDeleteController',AccommodationOccupancyDeleteController);

    AccommodationOccupancyDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccommodationOccupancy'];

    function AccommodationOccupancyDeleteController($uibModalInstance, entity, AccommodationOccupancy) {
        var vm = this;

        vm.accommodationOccupancy = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccommodationOccupancy.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
