(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('ServiceCategory1Controller', ServiceCategory1Controller);

    ServiceCategory1Controller.$inject = ['ServiceCategory1', 'ServiceCategory1Search'];

    function ServiceCategory1Controller(ServiceCategory1, ServiceCategory1Search) {

        var vm = this;

        vm.serviceCategory1S = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ServiceCategory1.query(function(result) {
                vm.serviceCategory1S = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ServiceCategory1Search.query({query: vm.searchQuery}, function(result) {
                vm.serviceCategory1S = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
