(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .directive('equalHeights', equalHeightsDirective)

    /**
     * Equal Heights
     *
     * Attach this directive to the parent/wrapping element of
     * a bunch of elements that are columns. This directive will
     * calculate the height of every direct child (one level down)
     * then set all of them to be the height of the tallest one.
     *
     */

    equalHeightsDirective.$inject = ['$timeout'];

    function equalHeightsDirective($timeout) {
        function link($scope, $element, attrs) {
            $timeout(function() {
                var $children        = $element.children(),
                    currentMaxHeight = 0,
                    numImagesLoaded  = 0,
                    $images          = $element.find('img'),
                    imageCount       = $images.length;

                console.log($children);

                if (imageCount > 0) {
                    angular.forEach($images, function(image) {
                        if (image.complete) {
                            numImagesLoaded++;
                        }
                    });
                }

                if (numImagesLoaded === imageCount) {
                    angular.forEach($children, function(child) {
                        var childHeight = $(child).outerHeight();

                        if (childHeight > currentMaxHeight) {
                            currentMaxHeight = childHeight;
                        }
                    });
                    console.log(currentMaxHeight);

                    // set heights
                    $children.css({height: currentMaxHeight});
                }
            }, 1000);
        }

        return {
            restrict: 'A',
            scope: {},
            link: link
        };
    }


})();
