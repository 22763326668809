(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('GoodsCategoryOneController', GoodsCategoryOneController);

    GoodsCategoryOneController.$inject = ['GoodsCategoryOne', 'GoodsCategoryOneSearch'];

    function GoodsCategoryOneController(GoodsCategoryOne, GoodsCategoryOneSearch) {

        var vm = this;

        vm.goodsCategoryOnes = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            GoodsCategoryOne.query(function(result) {
                vm.goodsCategoryOnes = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            GoodsCategoryOneSearch.query({query: vm.searchQuery}, function(result) {
                vm.goodsCategoryOnes = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
