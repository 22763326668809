(function() {
    "use strict";

    angular.module("pledgemasterApp").directive("siteMessages", siteMessages);
    siteMessages.$inject = ["$timeout"];

    function siteMessages($timeout) {
        return {
            restrict: "A",
            replace: false,
            link: function(scope, element) {
                scope.siteMessages = "";
                scope.setSiteMessage = function(msg) {
                    scope.siteMessages = msg;
                    $timeout(function() {
                        scope.siteMessages = "";
                    }, 3000);
                };
            }
        };
    }
})();
