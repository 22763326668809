(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossStatusController', RedcrossStatusController);

    RedcrossStatusController.$inject = ['RedcrossStatus', 'RedcrossStatusSearch'];

    function RedcrossStatusController(RedcrossStatus, RedcrossStatusSearch) {

        var vm = this;

        vm.redcrossStatuses = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            RedcrossStatus.query(function(result) {
                vm.redcrossStatuses = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            RedcrossStatusSearch.query({query: vm.searchQuery}, function(result) {
                vm.redcrossStatuses = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
