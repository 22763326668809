(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommsConditionDeleteController',AccommsConditionDeleteController);

    AccommsConditionDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccommsCondition'];

    function AccommsConditionDeleteController($uibModalInstance, entity, AccommsCondition) {
        var vm = this;

        vm.accommsCondition = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccommsCondition.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
