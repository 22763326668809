(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('InformationController', InformationController);

    InformationController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Auth'];

    function InformationController ($scope, Principal, LoginService, $state, Auth) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerPage = registerPage;
        vm.registerAccount = {};
        vm.success = null;

        vm.showInfo = false;
        vm.moreInfo = moreInfo;
        vm.lessmore = "More";

        vm.profile = profile;

        // window.scrollTo(0, 0);

        function profile(){
            $state.go("dashboard-prepop");
        }

        function moreInfo(){
            if (vm.showInfo) {
                vm.showInfo =false;
                vm.lessmore = "More";
            }
            else {
                vm.showInfo = true;
                vm.lessmore = "Less";
            }
        }

        if ($(document).width() > 700) {
            $('.butts').css("width", "25%");
            $('.butts').css("margin-bottom", "0");
            // $('.help_login').css("margin-bottom", "25px");
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function registerPage () {
            $state.go('register-ac');
        }
        function register () {

            vm.registerAccount.langKey = 'en';
            vm.registerAccount.login = vm.registerAccount.email;
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;

            Auth.createAccount(vm.registerAccount).then(function () {
                vm.success = 'OK';
                Auth.login({
                    username: vm.registerAccount.login,
                    password: vm.registerAccount.password,
                    rememberMe: false
                }).then(function () {
                    vm.authenticationError = false;
                    //$uibModalInstance.close();
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('dashboard-prepop');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                }).catch(function () {
                    vm.authenticationError = true;
                });
            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                } else if (response.status === 400 && response.data === 'email address already in use') {
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            });

        }
    }
})();
