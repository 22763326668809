(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RestrictionsDeleteController',RestrictionsDeleteController);

    RestrictionsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Restrictions'];

    function RestrictionsDeleteController($uibModalInstance, entity, Restrictions) {
        var vm = this;

        vm.restrictions = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Restrictions.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
