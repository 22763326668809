(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('DurationSearch', DurationSearch);

    DurationSearch.$inject = ['$resource'];

    function DurationSearch($resource) {
        var resourceUrl =  'api/_search/durations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
