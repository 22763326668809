(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('AbleToAccommodateSearch', AbleToAccommodateSearch);

    AbleToAccommodateSearch.$inject = ['$resource'];

    function AbleToAccommodateSearch($resource) {
        var resourceUrl =  'api/_search/able-to-accommodates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
