(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommCanYouAccomController', AccommCanYouAccomController);

    AccommCanYouAccomController.$inject = ['AccommCanYouAccom', 'AccommCanYouAccomSearch'];

    function AccommCanYouAccomController(AccommCanYouAccom, AccommCanYouAccomSearch) {

        var vm = this;

        vm.accommCanYouAccoms = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            AccommCanYouAccom.query(function(result) {
                vm.accommCanYouAccoms = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            AccommCanYouAccomSearch.query({query: vm.searchQuery}, function(result) {
                vm.accommCanYouAccoms = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
