(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('ServiceCategoryTwoDetailController', ServiceCategoryTwoDetailController);

    ServiceCategoryTwoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ServiceCategoryTwo', 'ServiceCategoryOne'];

    function ServiceCategoryTwoDetailController($scope, $rootScope, $stateParams, previousState, entity, ServiceCategoryTwo, ServiceCategoryOne) {
        var vm = this;

        vm.serviceCategoryTwo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:serviceCategoryTwoUpdate', function(event, result) {
            vm.serviceCategoryTwo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
