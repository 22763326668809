(function () {
    angular
        .module('pledgemasterApp')
        .config(smoothScrollConfig);

    smoothScrollConfig.$inject = ['$provide'];

    function smoothScrollConfig($provide) {
        $provide.decorator('$uiViewScroll', function () {
            return function (uiViewElement) {
                $('html,body').animate({
                    scrollTop: uiViewElement.offset().top
                }, 500);
            };
        });
    }

}());
