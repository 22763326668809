(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('ServiceCategoryOneController', ServiceCategoryOneController);

    ServiceCategoryOneController.$inject = ['ServiceCategoryOne', 'ServiceCategoryOneSearch'];

    function ServiceCategoryOneController(ServiceCategoryOne, ServiceCategoryOneSearch) {

        var vm = this;

        vm.serviceCategoryOnes = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            ServiceCategoryOne.query(function(result) {
                vm.serviceCategoryOnes = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ServiceCategoryOneSearch.query({query: vm.searchQuery}, function(result) {
                vm.serviceCategoryOnes = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
