(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossRejectedReasonDeleteController',RedcrossRejectedReasonDeleteController);

    RedcrossRejectedReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'RedcrossRejectedReason'];

    function RedcrossRejectedReasonDeleteController($uibModalInstance, entity, RedcrossRejectedReason) {
        var vm = this;

        vm.redcrossRejectedReason = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RedcrossRejectedReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
