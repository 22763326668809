(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('UserProfileController', UserProfileController);

    UserProfileController.$inject = ['$sce', 'UserProfile', 'UserProfileSearch'];

    function UserProfileController($sce, UserProfile, UserProfileSearch) {

        var vm = this;

        vm.userProfiles = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};

        vm.createdAfter = "";
        vm.createdBefore = "";
        vm.availableFrom = "";
        vm.availableUntil = "";
        vm.updatedAfter = "";
        vm.updatedBefore = "";

        vm.hasAccomm = true;
        vm.hasService = true;
        vm.loadAll = true;

        // vm.filterAccomms = filterAccoms;

        vm.filterDateAfter = filterDateAfter;

        vm.reportText = "<b>Report Output</b><br>";

        vm.advancedSearch = advancedSearch;

        vm.filteredSearch = [];

        vm.sanitize = sanitize;
        vm.advancedSearchModel = {};

        vm.csv = csv;

        loadAll();

        function filterAccomms(filteredList){
            //vm.reportText += "<br>Has No Accomm Pledge";
            return filteredList.filter(function(entry) {
                return vm.hasAccomm == (entry.userAcomPledges.length > 0) ? true : false ;
            })
        }
        function filterService(filteredList){
            //vm.reportText += "<br>Has No Service Pledge";
            return filteredList.filter(function(entry) {
                return vm.hasService == (entry.userServicesPledges.length > 0) ? true : false;
            })
        }
        function filterGoods(filteredList){
            vm.reportText += "<br>Has No Goods Pledge";
            return filteredList.filter(function(entry) {
                return vm.hasGoods == (entry.userAcomPledges.length > 0) ? true : false;
            })
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function csv(){
            console.log(convertArrayOfObjectsToCSV(vm.filteredSearch))
        }

        function convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args || null;
            if (data == null || !data.length) {
                return null;
            }

            var ignoreColumns = ["userAcomPledges","profileAddressCountry","redcrossVettedId", "organisationName", "redcrossVettedExpDate","userID","userGoodsPledges","userServicesPledges","vettedStatus","languageLists","organisationFlag", "id", "userProfileIdLink","useProfileAddress","linkedUserId"];
            var deepDiveColumns = ["type","owner","condition","bedrooms","occupancies","canYouAccoms"];

            var columns = ["firstName","lastName","phoneNumber","email","created","profileAddress1","profileAddress2","profileAddress3","profileAddressCityTownVillage","profileAddressArea","profileAddressAreaCode","lastModifiedBy","lastUpdated"]

            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(data[0]);

            // keys = keys.filter( function( rem ) {
            //     return !ignoreColumns.includes( rem );
            // } );

            var date = new Date();

            result = "USER PROFILE REPORT - " + date.getDate().toString() + " " + date.getMonthName() + " " + date.getFullYear();
            result += lineDelimiter;
            result += lineDelimiter;
            result += vm.reportText.replace("<b>", "").replace("</b>","").replace("<br>","using the filters ") + '\n\n';
            result += columns.join(columnDelimiter);
            result += lineDelimiter;
            result += lineDelimiter;


            data.forEach(function(item) {
                ctr = 0;
                columns.forEach(function(key) {
                    if (ctr > 0) result += columnDelimiter;

                    // if (deepDiveColumns.includes(key)) {
                    //     if ((item[key] != null) || (item[key] != "null")) result += item[key];
                    //     ctr++;
                    // }
                    // else {
                        if (item[key]) {
                            result += item[key].replace(/,/g, " ").replace(/\n/g, " ");
                        }
                        else{
                            result += " ";
                        }

                        ctr++;
                    // }
                });
                result += lineDelimiter;
            });

            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(result);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'UserProfileReport - ' + date.getDate().toString() + " " + date.getMonthName() + " " + date.getFullYear() + '.csv';
            hiddenElement.click();

            //return result;
        }


        function sanitize (str){
            return $sce.trustAsHtml(str);
        }

        function loadAll() {
            UserProfile.query(function(result) {
                vm.userProfiles = result;
                vm.userProfiles = vm.userProfiles.reverse();
                vm.searchQuery = "";
                vm.currentSearch = "";
                advancedSearch();
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            UserProfileSearch.query({query: vm.searchQuery}, function(result) {
                vm.userProfiles = result;
                vm.userProfiles = vm.userProfiles.reverse();
                vm.currentSearch = vm.searchQuery;
                vm.filteredSearch = vm.userProfiles;
                vm.reportText = "<b>" + vm.filteredSearch.length + " results </b> with search term \"" + vm.currentSearch + "\" ";
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }

        function email(filteredList){
            vm.reportText += "<br> - Email: " + vm.advancedSearchModel.email;
            return filteredList.filter(function(entry) {

                if (!entry.email) return false;
                return entry.email.toLowerCase().indexOf(vm.advancedSearchModel.email.toLowerCase()) >= 0;
            })
        }
        function firstName(filteredList){
            vm.reportText += "<br> - First Name: " + vm.advancedSearchModel.firstName;
            return filteredList.filter(function(entry) {
                if (!entry.firstName) return false;
                return entry.firstName.toLowerCase().indexOf(vm.advancedSearchModel.firstName.toLowerCase()) >= 0;
            })
        }

        function surname(filteredList){
            vm.reportText += "<br> - Surname: " + vm.advancedSearchModel.surname;
            return filteredList.filter(function(entry) {
                if (!entry.lastName) return false;
                return entry.lastName.toLowerCase().indexOf(vm.advancedSearchModel.surname.toLowerCase()) >= 0;
            })
        }
        function counties(filteredList){
            vm.reportText += "<br> - In County: " + vm.advancedSearchModel.county;
            return filteredList.filter(function(entry) {
                if (!entry.profileAddressArea) return false;
                return entry.profileAddressArea.toLowerCase().indexOf(vm.advancedSearchModel.county.toLowerCase()) >= 0;
            })
        }
        function phone(filteredList){
            vm.reportText += "<br> - Phone: " + vm.advancedSearchModel.phone;
            return filteredList.filter(function(entry) {
                if (!entry.phoneNumber) return false;
                return entry.phoneNumber.toLowerCase().indexOf(vm.advancedSearchModel.phone.toLowerCase()) >= 0;
            })
        }
        function filterDateAfter(filteredList, dateFilterType, date){
            return filteredList.filter(function(entry) {
                // CREATED
                if (dateFilterType == "createdAfter"){
                    var savedDate = new Date(entry.created).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "createdBefore"){


                    var savedDate = new Date(entry.created).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate <= date;
                }
                else if (dateFilterType == "updatedAfter"){
                    //UPDATED


                    var savedDate = new Date(entry.lastUpdated).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "updatedBefore"){


                    var savedDate = new Date(entry.lastUpdated).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate <= date;
                }
            })
        }

        function advancedSearch(funk){
            console.log("ad")
            vm.reportText = "";
            vm.filteredSearch = vm.userProfiles;

            if (!vm.loadAll){
                vm.filteredSearch = filterAccomms(vm.filteredSearch);
                vm.filteredSearch = filterService(vm.filteredSearch);
            }
           // vm.filteredSearch = filterGoods(vm.filteredSearch);
            if (vm.advancedSearchModel.occupancy) vm.filteredSearch = occupancy(vm.filteredSearch);
            if (vm.advancedSearchModel.email) vm.filteredSearch = email(vm.filteredSearch);

            if (vm.advancedSearchModel.firstName) vm.filteredSearch = firstName(vm.filteredSearch);
            if (vm.advancedSearchModel.surname) vm.filteredSearch = surname(vm.filteredSearch);

            if (vm.advancedSearchModel.county) vm.filteredSearch = counties(vm.filteredSearch);
            if (vm.advancedSearchModel.phone) vm.filteredSearch = phone(vm.filteredSearch);

            if (vm.advancedSearchModel.date) vm.filteredSearch = date(vm.filteredSearch);

            // DATES
            if (vm.createdAfter){
                var dateString = new Date(vm.createdAfter).toString("dd/MM/yyyy");
                //var dateString = vm.createdAfter;
                var dateAfter = new Date(vm.createdAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;
                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "createdAfter", date)
                vm.reportText += "<br> - Created After: " + dateString;

            }
            if (vm.createdBefore) {
                var dateString = new Date(vm.createdBefore).toString("dd/MM/yyyy");
                // var dateString = vm.createdBefore;
                var dateAfter = new Date(vm.createdBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "createdBefore", date);
                vm.reportText += "<br> - Created Before: " + dateString;

            }
            if (vm.availableAfter) {
                var dateString = new Date(vm.availableAfter).toString("dd/MM/yyyy");
                // var dateString = vm.availableAfter;
                var dateAfter = new Date(vm.availableAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "availableFrom",date);
                vm.reportText += "<br> - Available From: " + dateString;
            }
            if (vm.availableBefore) {
                var dateString = new Date(vm.availableBefore).toString("dd/MM/yyyy");
                //   var dateString = vm.availableBefore;
                var dateAfter = new Date(vm.availableBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "availableUntil");

                vm.reportText += "<br> - Available Until: " + dateString;

            }
            if (vm.updatedAfter) {
                var dateString = new Date(vm.updatedAfter).toString("dd/MM/yyyy");
                //  var dateString = vm.updatedAfter;
                var dateAfter = new Date(vm.updatedAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "updatedAfter", date);
                vm.reportText += "<br> - Updated After: " + dateString;

            }
            if (vm.updatedBefore) {
                var dateString = new Date(vm.updatedBefore).toString("dd/MM/yyyy");
                var dateAfter = new Date(vm.updatedBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "updatedBefore");
                vm.reportText += "<br> - Updated Before: " + dateString;
            }

            if (vm.advancedSearchModel.status) vm.filteredSearch = status(vm.filteredSearch);

            if (vm.advancedSearchModel.matched) vm.filteredSearch = matched(vm.filteredSearch);
            if (vm.advancedSearchModel.recordedBy) vm.filteredSearch = recordedBy(vm.filteredSearch);

            if (vm.advancedSearchModel.restrictions) vm.filteredSearch = restrictions(vm.filteredSearch);
            if (vm.advancedSearchModel.status) vm.filteredSearch = status(vm.filteredSearch);

            vm.reportText = "<b>" + vm.filteredSearch.length + " results </b> with search term \"" + vm.currentSearch + "\" " + vm.reportText;
        }


    }
})();
