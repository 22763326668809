(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('MatchedSearch', MatchedSearch);

    MatchedSearch.$inject = ['$resource'];

    function MatchedSearch($resource) {
        var resourceUrl =  'api/_search/matcheds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
