(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccomCanYouAccomDeleteController',AccomCanYouAccomDeleteController);

    AccomCanYouAccomDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccomCanYouAccom'];

    function AccomCanYouAccomDeleteController($uibModalInstance, entity, AccomCanYouAccom) {
        var vm = this;

        vm.accomCanYouAccom = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccomCanYouAccom.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
