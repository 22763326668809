(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RegisterModalController', RegisterModalController);


    RegisterModalController.$inject = ['$state', '$translate', '$timeout', 'Auth', 'LoginService', '$uibModalInstance'];

    function RegisterModalController ($state, $translate, $timeout, Auth, LoginService, $uibModalInstance) {
        var vm = this;

       //
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.cancel = cancel;

        // if ($state.$current.self.name == "register-ac-success"){
        //     vm.success = 'OK';
        // }

        $timeout(function (){angular.element('#login').focus();});

        // Cancel (for modal)
        function cancel () {
            vm.registerAccount = {
                email: null,
                password: null
            };
            $uibModalInstance.dismiss('cancel');
        }

        // function registerModal() {
        //     vm.confirmPassword = vm.registerAccount.password;
        //     register();
        // }

        // Perform registratin from register modal
        function register () {
            //if (vm.$state.includes('admin'))
            // TODO get correct google analytics path
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/click/registration"});
           // else ga('send', 'pageView', "/virtual/register/click/home/module2");

            vm.registerAccount.langKey = 'en';
            vm.registerAccount.login = vm.registerAccount.email;
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;

            Auth.createAccount(vm.registerAccount).then(function () {
                vm.success = 'OK';
                $uibModalInstance.dismiss('cancel');
                Auth.login({
                    username: vm.registerAccount.email,
                    password: vm.registerAccount.password,
                    rememberMe: false
                }).then(function () {
                    vm.authenticationError = false;
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('dashboard-prepop');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                }).catch(function () {
                    $state.go("dashboard-prepop")
                    vm.authenticationError = true;
                });
            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                } else if (response.status === 400 && response.data === 'email address already in use') {
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            });

        }

    }
})();
