(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossScreeningOneStatusController', RedcrossScreeningOneStatusController);

    RedcrossScreeningOneStatusController.$inject = ['RedcrossScreeningOneStatus', 'RedcrossScreeningOneStatusSearch'];

    function RedcrossScreeningOneStatusController(RedcrossScreeningOneStatus, RedcrossScreeningOneStatusSearch) {

        var vm = this;

        vm.redcrossScreeningOneStatuses = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            RedcrossScreeningOneStatus.query(function(result) {
                vm.redcrossScreeningOneStatuses = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            RedcrossScreeningOneStatusSearch.query({query: vm.searchQuery}, function(result) {
                vm.redcrossScreeningOneStatuses = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
