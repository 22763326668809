(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dashboard', {
            parent: 'app',
            url: '/dashboard',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/dashboard/dashboard.html',
                    controller: 'DashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }]
            }
        }).state('dashboard-prepop', {
            parent: 'app',
            url: '/dashboard/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/dashboard/dashboard.html',
                    controller: 'DashboardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dashboard');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                    var zz = UserProfileById.get({id : 0}).$promise;
                    return zz;
                }],
                pledgeGoods: ['$stateParams', 'PledgeGoods', function($stateParams, PledgeGoods) {
                    return PledgeGoods.query(function(result) {

                    });
                }],
                pledgeServices: ['$stateParams', 'PledgeServices', function($stateParams, PledgeServices) {
                    return PledgeServices.query(function(result) {

                    });
                }],
                pledgeAccommodations: ['$stateParams', 'PledgeAccommodations', function($stateParams, PledgeAccommodations) {
                    return PledgeAccommodations.query(function(result) {

                    });
                }]
            }
        }).state('dashboard-profile-edit', {
            parent: 'app',
            url: '/dashboard/profile/edit',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboard/user-profile-dialog.html',
                    controller: 'DBUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 0}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                }, function() {
                    $state.go('dashboard-prepop');
                });
            }]
        }).state('dashboard-profile-new', {
            parent: 'app',
            url: '/dashboard/profile/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboard/user-profile-dialog.html',
                    controller: 'DBUserProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firstName: null,
                                lastName: null,
                                phoneNumber: null,
                                profileAddress1: null,
                                profileAddress2: null,
                                profileAddress3: null,
                                profileAddressCityTownVillage: null,
                                profileAddressArea: null,
                                profileAddressCountry: null,
                                profileAddressAreaCode: null,
                                organisationFlag: null,
                                organisationName: null,
                                redcrossVettedId: null,
                                redcrossVettedExpDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                }, function() {
                    $state.go('dashboard-prepop');
                });
            }]
        }).state('dashboard-goods-new', {
            parent: 'app',
            url: '/dashboard/goods/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboard/pledge-goods-dialog.html',
                    controller: 'DBPledgeGoodsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                goodsStartDate: null,
                                goodsEndDate: null,
                                useProfileAddress: null,
                                goodsAddressOne: null,
                                goodsAddressTwo: null,
                                goodsAddressThree: null,
                                goodsCityTownVillage: null,
                                goodsAreaCounty: null,
                                goodsAreaCountry: null,
                                goodsPostCode: null,
                                goodsSharingConsent: null,
                                goodsGeoLocation: null,
                                id: null
                            };
                        },
                        profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 2}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                }, function() {
                    $state.go('dashboard-prepop');
                });
            }]
        }).state('dashboard-goods-edit', {
                parent: 'app',
                url: '/dashboard/goods/{id}/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/dashboard/pledge-goods-dialog.html',
                        controller: 'DBPledgeGoodsDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PledgeGoods', function(PledgeGoods) {
                                return PledgeGoods.get({id : $stateParams.id}).$promise;
                            }],
                            profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                                var zz = UserProfileById.get({id : 2}).$promise;
                                return zz;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                    }, function() {
                        $state.go('dashboard-prepop');
                    });
                }]
            }).state('dashboard-accommodation-new', {
            parent: 'app',
            url: '/dashboard/accommodation/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboard/pledge-accommodations-dialog.html',
                    controller: 'DBPledgeAccommodationsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                accommodationAdditionalDetails: null,
                                accommodationStartDate: null,
                                accommodationEndDate: null,
                                useProfileAddress: null,
                                accommodationAddress1: null,
                                accommodationAddress2: null,
                                accommodationAddress3: null,
                                accommodationCityTown: null,
                                accommodationAreaCounty: null,
                                accommodationCountry: null,
                                accommodationPostCode: null,
                                accommodationSharingConsent: null,
                                accommodationGeoLocation: null,
                                linkedUserId: null,
                                id: null
                            };
                        },
                        profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 2}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                }, function() {
                    $state.go('dashboard-prepop');
                });
            }]
        })
            .state('dashboard-accommodation-edit', {
                parent: 'app',
                url: '/dashboard/accommodations/{id}/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/dashboard/pledge-accommodations-dialog.html',
                        controller: 'DBPledgeAccommodationsDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PledgeAccommodations', function(PledgeAccommodations) {
                                return PledgeAccommodations.get({id : $stateParams.id}).$promise;
                            }],
                            profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                                var zz = UserProfileById.get({id : 2}).$promise;
                                return zz;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                    }, function() {
                        $state.go('dashboard-prepop');
                    });
                }]
            }).state('dashboard-services-new', {
            parent: 'app',
            url: '/dashboard/services/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/dashboard/pledge-services-dialog.html',
                    controller: 'DBPledgeServicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                serviceQualification: null,
                                serviceHours: null,
                                serviceAdditionalInfo: null,
                                serviceStartDate: null,
                                serviceEndDate: null,
                                useProfileAddress: true,
                                serviceAddress1: null,
                                serviceAddress2: null,
                                serviceAddress3: null,
                                serviceCityTownVillage: null,
                                serviceAreaCounty: null,
                                serviceCountry: null,
                                servicePostCode: null,
                                serviceSharingConsent: null,
                                serviceGeoLocation: null,
                                id: null
                            };
                        },
                        profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 2}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                }, function() {
                    $state.go('dashboard-prepop');
                });
            }]
        })
            .state('dashboard-services-edit', {
                parent: 'app',
                url: 'dashboard/services/{id}/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/dashboard/pledge-services-dialog.html',
                        controller: 'DBPledgeServicesDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PledgeServices', function(PledgeServices) {
                                return PledgeServices.get({id : $stateParams.id}).$promise;
                            }],
                            profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                                var zz = UserProfileById.get({id : 2}).$promise;
                                return zz;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('dashboard-prepop', null, { reload: 'dashboard-prepop' });
                    }, function() {
                        $state.go('dashboard-prepop');
                    });
                }]
            })
    }
})();
