(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function stateConfig($stateProvider, $urlRouterProvider) {

        $urlRouterProvider.when('/pledging', '/pledging/property');

        $stateProvider.state('pledging', {
            parent: 'app',
            url: '/pledging',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/pledging/pledging.html',
                    controller: 'PledgingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('pledging');
                    return $translate.refresh();
                }]
            }
        }).state('pledging-property', {
            parent: 'pledging',
            url: '/property',
            data: {
                authorities: []
            },
            templateUrl: 'app/pledging/property.html',
            controller: 'PledgingController',
            controllerAs: 'vm',
            // views: {
            //     'pledge@': {
            //     }
            // },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('pledging-property');
                    return $translate.refresh();
                }]
            }
        }).state('pledging-room', {
            parent: 'pledging',
            url: '/room',
            data: {
                authorities: []
            },
            templateUrl: 'app/pledging/room.html',
            controller: 'PledgingController',
            controllerAs: 'vm',
            // views: {
            //     'pledge@': {
            //     }
            // },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('pledging-property');
                    return $translate.refresh();
                }]
            }
        }).state('pledging-services', {
            parent: 'pledging',
            url: '/services',
            data: {
                authorities: []
            },
            templateUrl: 'app/pledging/services.html',
            controller: 'PledgingController',
            controllerAs: 'vm',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('pledging-services');
                    return $translate.refresh();
                }]
            }
        }).state('pledging-goods', {
            parent: 'pledging',
            url: '/goods',
            data: {
                authorities: []
            },
            templateUrl: 'app/pledging/goods.html',
            controller: 'PledgingController',
            controllerAs: 'vm',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('pledging-goods');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
