(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'RegisterService', '$state', 'Auth', '$uiViewScroll'];

    function HomeController ($scope, Principal, LoginService, RegisterService, $state, Auth, $uiViewScroll) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = loginAnalytics;
        vm.register = register;
        vm.registerPage = registerPage;
        vm.registerModal = registerModal;
        vm.registerAccount = {};
        vm.success = null;
        vm.cookieClick = cookieClick;
        vm.cookieView = true;
        vm.setCookieView = setCookieView;
        vm.carouselActive = 0;
        vm.pledgingPropertyMoreInfo = pledgingPropertyMoreInfo;
        vm.pledgingRoomMoreInfo = pledgingRoomMoreInfo;

        vm.pledgeServices = pledgeServices;
        vm.pledgeGoods = pledgeGoods;

        vm.viewCriteria = viewCriteria;
        vm.reveal = "View ";

        vm.criteria = false;

        // TODO maybe move elsewhere
        vm.pledgeCarouselData = {
            property: {
                title: "Pledge a Spare Room",
                //subTitle: "How it works?",
                icon: "room",
                items: [
                    "You will receive an acknowledgement call to check the details of your offer over the phone.",
                    "As soon as is feasible following that, you will receive a call to arrange a visit to meet you at your property for a brief assessment, at your convenience.",
                    "Following that we will work to match your offer to suitable person(s) newly arrived in Ireland.",
                    "If a match is made, with your permission, we will arrange a meeting with you, a caseworker and the matched person(s).",
                    "If all parties are happy to proceed, we will arrange a move date.",
                    "We support with the move, both practically and socially, and your Red Cross or Consortium caseworker will be in regular contact to support you, your community and the people using our service.",
                    "Hosts accommodating children under 16 in a shared property must undergo Garda Vetting."
                ]
            },
            room: {
                title: "Pledge a Vacant Property",
                //subTitle: "How it works?",
                icon: "house",
                items: [
                    "You will receive an acknowledgement call to check the details of your offer over the phone.",
                    "As soon as is feasible following that, you will receive a call to arrange a visit to meet you at your property for an assessment, at your convenience.",
                    "Following that we will work to match your offer with suitable persons or a family newly arrived in Ireland.",
                    "If a match is made, with your permission, we will arrange a meeting with you, a caseworker and the matched person(s).",
                    "If all parties are happy to proceed, we will arrange a move date.",
                    "We support with the move, both practically and socially, and your Red Cross caseworker or Consortium will be in regular contact to support you, your community and the people using our service."
                ]
            }
        };

        function viewCriteria(){

            if (vm.criteria) {vm.criteria = false; vm.reveal = "View ";}
            else {vm.criteria = true; vm.reveal = "Hide "}
        }

        // console.log ("DOC WIDTH ", $(document).width())
        // if ($(document).width() > 700) {
        //     $('.butts').css("width", "25%");
        //     $('.butts').css("margin-bottom", "0");
        //     $('.banner').css("background-position", "50% 50%");
        //     // $('.help_login').css("margin-bottom", "25px");
        //     $('.banner__inner').css("margin-top", "200px");
        //     $('.banner__inner').css("margin-bottom", "50px");
        // }
        // else{
        //     $('.banner').css("background-position", "70% 50%");
        //     $('.banner__inner').css("margin-top", "250px");
        //     $('.banner__inner').css("margin-bottom", "20px");
        // }

        checkUserAgent();

        function checkUserAgent(){
            var ua = window.navigator.userAgent.toString().toLowerCase();
            if ((ua.indexOf("iphone")>=0) && (ua.indexOf("safari")>=0) && (ua.indexOf("chrome")<0)){
                // $('.row').css("width", "90%");
                // $('.row').css("margin-left", "5%");
            }
        }


        function registerModal() {
            //if (vm.$state.includes('admin'))
            // TODO add proper ga path
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/open-modal/home/header/" });
            //else ga('send', 'pageView', "/admin/virtual/login/open-modal/home/header/");
            RegisterService.open();
        }

        function loginAnalytics(e){
            if (e == 1){
                //if (vm.$state.includes('admin'))
                    ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/login/open-modal/home/module2"});
                //else ga('send', 'pageView', "/admin/virtual/login/open-modal/home/module2");
            }
            else if (e == 2){
                //if (vm.$state.includes('admin'))
                    ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/login/open-modal/home/module5" });
               // else ga('send', 'pageView', "/admin/virtual/login/open-modal/home/module5");
            }


            LoginService.open();
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        setCookieView();

        function setCookieView(){
            if (typeof(Storage) !== "undefined") {
                //localStorage.setItem("authorities", vm.account.authorities)
                if (localStorage.getItem("cookiePolicy")) {
                    console.log(localStorage.getItem("cookiePolicy"))
                    vm.cookieView = localStorage.getItem("cookiePolicy");
                    console.log(vm.cookieView)
                }
                else{
                    console.log("e")
                    vm.cookieView = true;
                    localStorage.setItem("cookiePolicy", true);
                }
                cookieClick()
            }
        }

        function cookieClick(){
            console.log("_________")
                if (typeof(Storage) !== "undefined") {
                    // Code for localStorage/sessionStorage.
                    vm.cookieView = false;
                    localStorage.setItem("cookiePolicy", false);
                }
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function registerPage () {
            //if (vm.$state.includes('admin')) ga('send', 'pageView', "/virtual/register/open-page/home/module5");
           // else
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/open-page/home/module5"});
            $state.go('register-ac');
        }
        // The top register area
        function register () {
            //if (vm.$state.includes('admin'))
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/register/click/home/module2"});
           // else ga('send', 'pageView', "/virtual/register/click/home/module2");

            vm.registerAccount.langKey = 'en';
            vm.registerAccount.login = vm.registerAccount.email;
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;

            Auth.createAccount(vm.registerAccount).then(function () {
                vm.success = 'OK';
                Auth.login({
                    username: vm.registerAccount.email,
                    password: vm.registerAccount.password,
                    rememberMe: false
                }).then(function () {
                    vm.authenticationError = false;
                    //$uibModalInstance.close();
                    if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                        $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                        $state.go('dashboard-prepop');
                    }

                    $rootScope.$broadcast('authenticationSuccess');

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                }).catch(function () {
                    $state.go("dashboard-prepop")
                    vm.authenticationError = true;
                });
            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                } else if (response.status === 400 && response.data === 'email address already in use') {
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            });

        }

        function pledgingPropertyMoreInfo() {
            $state.go('pledging-property')
                .then(function(){
                    $uiViewScroll($("#pledging-content-area"));
                });
        }
        function pledgingRoomMoreInfo() {
            $state.go('pledging-room')
                .then(function(){
                    $uiViewScroll($("#pledging-content-area"));
                });
        }


        function pledgeServices() {
            if (vm.isAuthenticated()) {
                $state.go("dashboard-prepop");
            } else {
                registerModal();
            }
        }

        function pledgeGoods() {
            if (vm.isAuthenticated()) {
                $state.go("dashboard-prepop");
            } else {
                registerModal();
            }
        }
    }
})();
