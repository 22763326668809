(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RestrictionsController', RestrictionsController);

    RestrictionsController.$inject = ['Restrictions', 'RestrictionsSearch'];

    function RestrictionsController(Restrictions, RestrictionsSearch) {

        var vm = this;

        vm.restrictions = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Restrictions.query(function(result) {
                vm.restrictions = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            RestrictionsSearch.query({query: vm.searchQuery}, function(result) {
                vm.restrictions = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
