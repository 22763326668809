(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeAccommodationDetailController', PledgeAccommodationDetailController);

    PledgeAccommodationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PledgeAccommodation', 'AccomOccupancy', 'AccomCanYouAccom', 'AccomFacilities', 'AccomLocalAmenities'];

    function PledgeAccommodationDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PledgeAccommodation, AccomOccupancy, AccomCanYouAccom, AccomFacilities, AccomLocalAmenities) {
        var vm = this;

        vm.pledgeAccommodation = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('pledgemasterApp:pledgeAccommodationUpdate', function(event, result) {
            vm.pledgeAccommodation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
