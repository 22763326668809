(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommsOccupancyDeleteController',AccommsOccupancyDeleteController);

    AccommsOccupancyDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccommsOccupancy'];

    function AccommsOccupancyDeleteController($uibModalInstance, entity, AccommsOccupancy) {
        var vm = this;

        vm.accommsOccupancy = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccommsOccupancy.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
