(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeAccommodationsController', PledgeAccommodationsController);

    PledgeAccommodationsController.$inject = ['$http','$sce','DataUtils', 'PledgeAccommodations', 'PledgeAccommodationsSearch', 'AccommsType', 'AccommsOwner', 'AccommsCondition', 'AccommsBedrooms', 'AccommsOccupancy', 'AccommCanYouAccom', 'AccommsFacilities', 'AccommsLocalAmenities'];

    function PledgeAccommodationsController($http, $sce, DataUtils, PledgeAccommodations, PledgeAccommodationsSearch, AccommsType, AccommsOwner, AccommsCondition, AccommsBedrooms, AccommsOccupancy, AccommCanYouAccom, AccommsFacilities, AccommsLocalAmenities) {

        var vm = this;

        vm.limit = 10;

        vm.pledgeAccommodations = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        //
        // vm.page = 1;
        // vm.totalItems = null;
        // vm.itemsPerPage = paginationConstants.itemsPerPage;


        // vm.accommstypes = AccommsType.query();
        // vm.accommsowners = AccommsOwner.query();
        // vm.accommsconditions = AccommsCondition.query();
        // vm.accommsbedrooms = AccommsBedrooms.query();
        // vm.accommsoccupancies = AccommsOccupancy.query();
        // vm.accommcanyouaccoms = AccommCanYouAccom.query();
        // vm.accommsfacilities = AccommsFacilities.query();
        //vm.accommslocalamenities = AccommsLocalAmenities.query();

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.createdAfter = "";
        vm.createdBefore = "";
        vm.availableFrom = "";
        vm.availableUntil = "";
        vm.updatedAfter = "";
        vm.updatedBefore = "";



        vm.filterDateAfter = filterDateAfter;

        //vm.currentSearch = "";
        //search();
        vm.reportText = "<b>Report Output</b><br>";

        vm.advancedSearch = advancedSearch;

        vm.filteredSearch = [];

        vm.sanitize = sanitize;
        vm.advancedSearchModel = {};

        vm.csv = csv;

        function csv(){
            console.log(convertArrayOfObjectsToCSV(vm.filteredSearch))
        }

        function convertArrayOfObjectsToCSV(args) {
            var result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args || null;
            if (data == null || !data.length) {
                return null;
            }

            var deepDiveColumns = ["type","owner","condition","bedrooms","occupancies","canYouAccoms"];

            var columns = ["occupancies", "firstName","secondName","phoneNumber","email","created","accommodationAdditionalDetails", "adminNotes", "status", "accommodationAddress1",
                "accommodationAddress2","accommodationAddress3","accommodationCityTown","accommodationAreaCounty",
                "accommodationPostCode","bedrooms","accommodationStartDate", "accommodationEndDate",
                "lastModifiedBy","lastUpdated"]


            columnDelimiter = args.columnDelimiter || ',';
            lineDelimiter = args.lineDelimiter || '\n';

            keys = Object.keys(data[0]);

            var date = new Date();

            result = "PLEDGED ACCOMMODATIONS REPORT - " + date.getDate().toString() + " " + date.getMonthName() + " " + date.getFullYear();
            result += lineDelimiter;
            result += lineDelimiter;
            result += vm.reportText.replace("<b>", "").replace("</b>","").replace("<br>","using the filters ") + '\n\n';
            result += columns.join(columnDelimiter);
            result += lineDelimiter;
            result += lineDelimiter;

            data.forEach(function(item) {
                ctr = 0;
                columns.forEach(function(key) {
                    if (ctr > 0) result += columnDelimiter;

                    // if (deepDiveColumns.includes(key)) {
                    //     // item[k].forEach(function())
                    //     if (item[key]) result += item[key];
                    //     ctr++;
                    // }
                    // else {
                    if (item[key]) {
                        if (key == "bedrooms"){
                            var beds = item[key].amount;
                            result += beds.replace(/,/g, " ").replace(/\n/g, " ");
                        }
                        else if (key == "occupancies"){
                            var occupancy = item[key];
                            if (occupancy[0]) result += occupancy[0].accommsOccupancy.replace(/,/g, " ").replace(/\n/g, " ");
                            for (var i = 1; i < occupancy.length; i++){
                                result += " & " + occupancy[0].accommsOccupancy.replace(/,/g, " ").replace(/\n/g, " ");
                            }
                        }
                        else{
                            result += item[key].replace(/,/g, " ").replace(/\n/g, " ");
                        }

                    }
                    else{
                        result += " ";
                    }
                        ctr++;
                    // }
                });
                result += lineDelimiter;
            });

            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(result);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'AccommodationsReport - ' + date.getDate().toString() + " " + date.getMonthName() + " " + date.getFullYear() + '.csv';
            hiddenElement.click();

            //return result;
        }


        function sanitize (str){
            return $sce.trustAsHtml(str);
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // removed for performance reasons.
        // loadAll();

        function occupancy(filteredList){
            vm.reportText += "<br> - Occupancy: " + vm.advancedSearchModel.occupancy;
            return filteredList.filter(function(entry) {
                var e = entry.occupancies;
                console.log("occupancy", e.toString().toLowerCase());

                for (var i = 0; i < e.length; i++){
                    if (e[i].accommsOccupancy.toLowerCase().indexOf(vm.advancedSearchModel.occupancy.toLowerCase()) >= 0) return true;
                }
                return false
            })
        }
        function email(filteredList){
            vm.reportText += "<br> - Email: " + vm.advancedSearchModel.email;
            return filteredList.filter(function(entry) {

                if (!entry.email) return false;
                return entry.email.toLowerCase().indexOf(vm.advancedSearchModel.email.toLowerCase()) >= 0;
            })
        }
        function firstName(filteredList){
            vm.reportText += "<br> - First Name: " + vm.advancedSearchModel.firstName;
            return filteredList.filter(function(entry) {
                if (!entry.firstName) return false;
                return entry.firstName.toLowerCase().indexOf(vm.advancedSearchModel.firstName.toLowerCase()) >= 0;
            })
        }

        function surname(filteredList){
            vm.reportText += "<br> - Surname: " + vm.advancedSearchModel.surname;
            return filteredList.filter(function(entry) {
                if (!entry.secondName) return false;
                return entry.secondName.toLowerCase().indexOf(vm.advancedSearchModel.surname.toLowerCase()) >= 0;
            })
        }
        function accommodationType(filteredList){
            vm.reportText += "<br> - Accommodation Type: " + vm.advancedSearchModel.type;
            return filteredList.filter(function(entry) {
                return entry.accommodationType.type.toLowerCase().indexOf(vm.advancedSearchModel.type.toLowerCase()) >= 0;
            })
        }
        function rooms(filteredList){
            vm.reportText += "<br> - Number of Rooms: " + vm.advancedSearchModel.rooms;
            return filteredList.filter(function(entry) {
                console.log("rooms", entry);
                return entry.numberOfBedRooms.number.toString().toLowerCase().indexOf(vm.advancedSearchModel.rooms.toLowerCase()) >= 0;
            })
        }
        function towns(filteredList){
            vm.reportText += "<br> - Town: " + vm.advancedSearchModel.towns;
            return filteredList.filter(function(entry) {
                return entry.towns.towns.toLowerCase().indexOf(vm.advancedSearchModel.towns.toLowerCase()) >= 0;
            })
        }
        function counties(filteredList){
            vm.reportText += "<br> - In County: " + vm.advancedSearchModel.county;
            return filteredList.filter(function(entry) {
                if (!entry.accommodationAreaCounty) return false;
                return entry.accommodationAreaCounty.toLowerCase().indexOf(vm.advancedSearchModel.county.toLowerCase()) >= 0;
            })
        }
        function phone(filteredList){
            vm.reportText += "<br> - Phone: " + vm.advancedSearchModel.phone;
            return filteredList.filter(function(entry) {
                if (!entry.phoneNumber) return false;
                return entry.phoneNumber.toLowerCase().indexOf(vm.advancedSearchModel.phone.toLowerCase()) >= 0;
            })
        }
        function date(filteredList){
            vm.reportText += "<br> - Date Assessed: " + vm.advancedSearchModel.date;
            // if date contains special range characters then split into two dates, convert to timestamps and filter return all within raange

            var dates = [];
            var dateQuery = vm.advancedSearchModel.date;
            if (dateQuery.indexOf(" - ") >= 0 ) dates = dateQuery.split(" - ");
            else if (dateQuery.indexOf(" to ") >= 0 ) dates = dateQuery.split(" to ");

            if (dates.length > 1){
                dates[0] = Date.parse(dates[0]).getTime()/1000;
                dates[1] = Date.parse(dates[1]).getTime()/1000;

                return filteredList.filter(function(entry) {
                    console.log("date", entry.accommodationStartDate);
                    var savedDate = new Date(entry.accommodationStartDate).toString("dd/MM/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;

                    return  savedDate >= dates[0] && savedDate <= dates[1];
                })
            }
            else{
                return filteredList.filter(function(entry) {
                    console.log("date", entry.accommodationStartDate);
                    var savedDate = new Date(entry.accommodationStartDate).toString("dd/MM/yyyy");
                    var savedDateShort = new Date(entry.accommodationStartDate).toString("d/M/yyyy");

                    if (savedDate.toLowerCase().indexOf(vm.advancedSearchModel.date) >= 0) return true;
                    else return savedDateShort.toLowerCase().indexOf(vm.advancedSearchModel.date) >= 0;
                })
            }
        }
        function status(filteredList){
            vm.reportText += "<br> - Status: " + vm.advancedSearchModel.status;
            return filteredList.filter(function(entry) {
                console.log(entry)
                if (!entry.status) return false;
                return entry.status.toLowerCase().indexOf(vm.advancedSearchModel.status.toLowerCase()) >= 0;
            })
        }
        function matched(filteredList){
            vm.reportText += "<br> - Matched: " + vm.advancedSearchModel.matched;
            return filteredList.filter(function(entry) {
                return entry.matched.matched.toLowerCase().indexOf(vm.advancedSearchModel.matched.toLowerCase()) >= 0;
            })
        }

        function filterDateAfter(filteredList, dateFilterType, date){
            return filteredList.filter(function(entry) {
                // CREATED
                if (dateFilterType == "createdAfter"){
                    var savedDate = new Date(entry.created).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "createdBefore"){


                    var savedDate = new Date(entry.created).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate <= date;
                }
                else if (dateFilterType == "availableFrom"){
                    //AVAILABLE


                    var savedDate = new Date(entry.accommodationStartDate).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "availableUntil"){


                    var savedDate = new Date(entry.accommodationEndDate).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "updatedAfter"){
                    //UPDATED


                    var savedDate = new Date(entry.lastUpdated).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    if (savedDate == 0) return false;

                    return savedDate >= date;
                }
                else if (dateFilterType == "updatedBefore"){


                    var savedDate = new Date(entry.lastUpdated).toString("MM/dd/yyyy");
                    if (savedDate) savedDate = Date.parse(savedDate).getTime() / 1000;
                    //if (savedDate == 0) return false;

                    return savedDate <= date;
                }
            })
        }

        function advancedSearch(funk){
            vm.reportText = "";
            vm.filteredSearch = vm.pledgeAccommodations;
            if (vm.advancedSearchModel.occupancy) vm.filteredSearch = occupancy(vm.filteredSearch);
            if (vm.advancedSearchModel.email) vm.filteredSearch = email(vm.filteredSearch);

            if (vm.advancedSearchModel.firstName) vm.filteredSearch = firstName(vm.filteredSearch);
            if (vm.advancedSearchModel.surname) vm.filteredSearch = surname(vm.filteredSearch);

            if (vm.advancedSearchModel.county) vm.filteredSearch = counties(vm.filteredSearch);
            if (vm.advancedSearchModel.phone) vm.filteredSearch = phone(vm.filteredSearch);

            if (vm.advancedSearchModel.date) vm.filteredSearch = date(vm.filteredSearch);

            // DATES
            if (vm.createdAfter){
                var dateString = new Date(vm.createdAfter).toString("dd/MM/yyyy");
                //var dateString = vm.createdAfter;
                var dateAfter = new Date(vm.createdAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;
                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "createdAfter", date)
                vm.reportText += "<br> - Created After: " + dateString;

            }
            if (vm.createdBefore) {
                var dateString = new Date(vm.createdBefore).toString("dd/MM/yyyy");
               // var dateString = vm.createdBefore;
                var dateAfter = new Date(vm.createdBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "createdBefore", date);
                vm.reportText += "<br> - Created Before: " + dateString;

            }
            if (vm.availableAfter) {
                var dateString = new Date(vm.availableAfter).toString("dd/MM/yyyy");
               // var dateString = vm.availableAfter;
                var dateAfter = new Date(vm.availableAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "availableFrom",date);
                vm.reportText += "<br> - Available From: " + dateString;
            }
            if (vm.availableBefore) {
                var dateString = new Date(vm.availableBefore).toString("dd/MM/yyyy");
             //   var dateString = vm.availableBefore;
                var dateAfter = new Date(vm.availableBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "availableUntil");

                vm.reportText += "<br> - Available Until: " + dateString;

            }
            if (vm.updatedAfter) {
                var dateString = new Date(vm.updatedAfter).toString("dd/MM/yyyy");
              //  var dateString = vm.updatedAfter;
                var dateAfter = new Date(vm.updatedAfter).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "updatedAfter", date);
                vm.reportText += "<br> - Updated After: " + dateString;

            }
            if (vm.updatedBefore) {
                var dateString = new Date(vm.updatedBefore).toString("dd/MM/yyyy");
                var dateAfter = new Date(vm.updatedBefore).toString("MM/dd/yyyy");
                var date = Date.parse(dateAfter).getTime()/1000;

                vm.filteredSearch = filterDateAfter(vm.filteredSearch, "updatedBefore");
                vm.reportText += "<br> - Updated Before: " + dateString;
            }

            if (vm.advancedSearchModel.status) vm.filteredSearch = status(vm.filteredSearch);

            if (vm.advancedSearchModel.matched) vm.filteredSearch = matched(vm.filteredSearch);
            if (vm.advancedSearchModel.recordedBy) vm.filteredSearch = recordedBy(vm.filteredSearch);

            if (vm.advancedSearchModel.restrictions) vm.filteredSearch = restrictions(vm.filteredSearch);
            if (vm.advancedSearchModel.status) vm.filteredSearch = status(vm.filteredSearch);

            vm.reportText = "<b>" + vm.filteredSearch.length + " results </b> with search term \"" + vm.currentSearch + "\" " + vm.reportText;
        }
        var t1 = Math.round(+new Date()/1000);

        function loadAll_ES () {

            $http({
                method: 'GET',
                url: 'http://ec2-34-243-229-7.eu-west-1.compute.amazonaws.com:9200/pledgeaccommodations/_search?pretty=true&q=*:*&size=1000&sort=created:desc',
                headers: {'Content-Type': 'application/json'}
            }).success(function (response) {
                vm.pa = angular.fromJson(response.hits.hits);
                //vm.pa = vm.pa.reverse();

                vm.searchQuery = "";
                vm.currentSearch = "";

                for (var i = 0; i<vm.pa.length; i++){
                    vm.pledgeAccommodations[i] = {};
                    vm.pledgeAccommodations[i].id = vm.pa[i]._source.id;
                    vm.pledgeAccommodations[i].created = vm.pa[i]._source.created;
                    vm.pledgeAccommodations[i].occupancies = vm.pa[i]._source.occupancies;
                    vm.pledgeAccommodations[i].email = vm.pa[i]._source.email;
                    vm.pledgeAccommodations[i].firstName = vm.pa[i]._source.firstName;
                    vm.pledgeAccommodations[i].secondName = vm.pa[i]._source.secondName;
                    vm.pledgeAccommodations[i].phoneNumber = vm.pa[i]._source.phoneNumber;
                    vm.pledgeAccommodations[i].accommodationAreaCounty = vm.pa[i]._source.accommodationAreaCounty;
                    vm.pledgeAccommodations[i].status = vm.pa[i]._source.status;
                    vm.pledgeAccommodations[i].linkedUserId = vm.pa[i]._source.linkedUserId;
                    vm.pledgeAccommodations[i].bedrooms = vm.pa[i]._source.bedrooms;
                    vm.pledgeAccommodations[i].canYouAccoms = vm.pa[i]._source.canYouAccoms;
                }

                vm.filteredSearch = vm.pledgeAccommodations;

                // var accoms = pledgeAccommodations[0]._source.canYouAccoms;
                // var email = pledgeAccommodations[0]._source.email;

                 advancedSearch();
                 var t2 = t1 - Math.round(+new Date()/1000);
            }).error(function (qwe) {
                var temp = qwe.statusText;
            });

        }

        function loadAll() {
            PledgeAccommodations.query(function(result) {
                vm.pledgeAccommodations = result;
                vm.pledgeAccommodations = vm.pledgeAccommodations.reverse();
                vm.filteredSearch = vm.pledgeAccommodations;
                vm.searchQuery = "";
                vm.currentSearch = "";
                advancedSearch();
                var t2 = t1 - Math.round(+new Date()/1000);
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PledgeAccommodationsSearch.query({query: vm.searchQuery}, function(result) {
                vm.pledgeAccommodations = result;
                vm.pledgeAccommodations = vm.pledgeAccommodations.reverse();
                vm.currentSearch = vm.searchQuery;
                vm.filteredSearch = vm.pledgeAccommodations;
                vm.reportText = "<b>" + vm.filteredSearch.length + " results </b> with search term \"" + vm.currentSearch + "\" ";
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
