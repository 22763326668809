(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('VerificationController', VerificationController);

    VerificationController.$inject = ['$rootScope', '$scope', 'Principal', 'LoginService', '$state', 'Auth', '$stateParams', '$http'];

    function VerificationController ($rootScope, $scope, Principal, LoginService, $state, Auth, $stateParams) {
        var vm = this;

        console.log($stateParams, "____");

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.verifyUser = verifyUser;
        vm.registerAccount = {};
        vm.success = null;
        vm.decode = atob($stateParams.key);
        vm.email = angular.fromJson(vm.decode).email;
        vm.activationCode = angular.fromJson(vm.decode).activationCode;
        vm.newpassword =

        console.log(vm.decode);


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function verifyUser(){
            Auth.login({
                username: vm.email,
                password: vm.activationCode,
                rememberMe: false
            }).then(function () {
                //vm.authenticationError = false;
                //$uibModalInstance.close();
                if ($state.current.name === 'verification' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    Auth.changePassword(vm.registerAccount.password, function(){
                        $state.go('dashboard-prepop');
                    });
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }
        function register () {

                vm.registerAccount.langKey = 'en';
                vm.registerAccount.login = vm.email;
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;

                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.success = 'OK';
                    Auth.login({
                        username: vm.registerAccount.login,
                        password: vm.registerAccount.password,
                        rememberMe: false
                    }).then(function () {
                        //vm.authenticationError = false;
                        //$uibModalInstance.close();
                        if ($state.current.name === 'verfication' || $state.current.name === 'activate' ||
                            $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                            $state.go('dashboard-prepop');
                        }

                        $rootScope.$broadcast('authenticationSuccess');

                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        if (Auth.getPreviousState()) {
                            var previousState = Auth.getPreviousState();
                            Auth.resetPreviousState();
                           // $state.go(previousState.name, previousState.params);
                        }
                    }).catch(function () {
                        vm.authenticationError = true;
                    });
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });

        }
    }
})();
