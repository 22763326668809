(function() {
    'use strict';
    angular
        .module('pledgemasterApp')
        .factory('PledgeGoods', PledgeGoods);

    PledgeGoods.$inject = ['$resource', 'DateUtils'];

    function PledgeGoods ($resource, DateUtils) {
        var resourceUrl =  'api/pledge-goods/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.goodsStartDate = DateUtils.convertDateTimeFromServer(data.goodsStartDate);
                        data.goodsEndDate = DateUtils.convertDateTimeFromServer(data.goodsEndDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
