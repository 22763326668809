(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('RedcrossScreeningTwoAssessmentStatusSearch', RedcrossScreeningTwoAssessmentStatusSearch);

    RedcrossScreeningTwoAssessmentStatusSearch.$inject = ['$resource'];

    function RedcrossScreeningTwoAssessmentStatusSearch($resource) {
        var resourceUrl =  'api/_search/redcross-screening-two-assessment-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
