(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommsOwnerDeleteController',AccommsOwnerDeleteController);

    AccommsOwnerDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccommsOwner'];

    function AccommsOwnerDeleteController($uibModalInstance, entity, AccommsOwner) {
        var vm = this;

        vm.accommsOwner = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccommsOwner.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
