(function () {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('SocialAuth', SocialAuth);

    SocialAuth.$inject = ['$resource'];

    function SocialAuth ($resource) {
        return $resource('social/signup', {}, {});
    }
})();
