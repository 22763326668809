(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeAccommodationsDialogController', PledgeAccommodationsDialogController);

    PledgeAccommodationsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'PledgeAccommodations', 'AccommsType', 'AccommsOwner', 'AccommsCondition', 'AccommsBedrooms', 'AccommsOccupancy', 'AccommCanYouAccom', 'AccommsFacilities', 'AccommsLocalAmenities'];

    function PledgeAccommodationsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, PledgeAccommodations, AccommsType, AccommsOwner, AccommsCondition, AccommsBedrooms, AccommsOccupancy, AccommCanYouAccom, AccommsFacilities, AccommsLocalAmenities) {
        var vm = this;

        vm.pledgeAccommodations = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.accommstypes = AccommsType.query();
        vm.accommsowners = AccommsOwner.query();
        vm.accommsconditions = AccommsCondition.query();
        vm.accommsbedrooms = AccommsBedrooms.query();
        vm.accommsoccupancies = AccommsOccupancy.query();
        vm.accommcanyouaccoms = AccommCanYouAccom.query();
        vm.accommsfacilities = AccommsFacilities.query();
        vm.accommslocalamenities = AccommsLocalAmenities.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.pledgeAccommodations.id !== null) {
                PledgeAccommodations.update(vm.pledgeAccommodations, onSaveSuccess, onSaveError);
            } else {
                PledgeAccommodations.save(vm.pledgeAccommodations, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:pledgeAccommodationsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.accommodationStartDate = false;
        vm.datePickerOpenStatus.accommodationEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
