(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('NumberOfBedRoomsDialogController', NumberOfBedRoomsDialogController);

    NumberOfBedRoomsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'NumberOfBedRooms'];

    function NumberOfBedRoomsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, NumberOfBedRooms) {
        var vm = this;

        vm.numberOfBedRooms = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.numberOfBedRooms.id !== null) {
                NumberOfBedRooms.update(vm.numberOfBedRooms, onSaveSuccess, onSaveError);
            } else {
                NumberOfBedRooms.save(vm.numberOfBedRooms, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:numberOfBedRoomsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
