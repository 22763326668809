(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pledge-services', {
            parent: 'entity',
            url: '/pledge-services',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pledgemasterApp.pledgeServices.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pledge-services/pledge-services.html',
                    controller: 'PledgeServicesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pledgeServices');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pledge-services-detail', {
            parent: 'pledge-services',
            url: '/pledge-services/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'pledgemasterApp.pledgeServices.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pledge-services/pledge-services-detail.html',
                    controller: 'PledgeServicesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('pledgeServices');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PledgeServices', function($stateParams, PledgeServices) {
                    return PledgeServices.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pledge-services',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('pledge-services-detail.edit', {
            parent: 'pledge-services-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pledge-services/pledge-services-dialog.html',
                    controller: 'PledgeServicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PledgeServices', function(PledgeServices) {
                            return PledgeServices.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pledge-services', null, { reload: 'pledge-services' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pledge-services.new', {
            parent: 'pledge-services',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pledge-services/pledge-services-dialog.html',
                    controller: 'PledgeServicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                serviceQualification: null,
                                serviceHours: null,
                                serviceAdditionalInfo: null,
                                serviceStartDate: null,
                                serviceEndDate: null,
                                useProfileAddress: null,
                                serviceAddress1: null,
                                serviceAddress2: null,
                                serviceAddress3: null,
                                serviceCityTownVillage: null,
                                serviceAreaCounty: null,
                                serviceCountry: null,
                                servicePostCode: null,
                                serviceSharingConsent: null,
                                serviceGeoLocation: null,
                                id: null
                            };
                        },
                        profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 2}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pledge-services', null, { reload: 'pledge-services' });
                }, function() {
                    $state.go('pledge-services');
                });
            }]
        })
        .state('pledge-services.edit', {
            parent: 'pledge-services',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pledge-services/pledge-services-dialog.html',
                    controller: 'PledgeServicesDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PledgeServices', function(PledgeServices) {
                            return PledgeServices.get({id : $stateParams.id}).$promise;
                        }],
                        profile: ['$stateParams', 'UserProfileById', function($stateParams, UserProfileById) {
                            var zz = UserProfileById.get({id : 2}).$promise;
                            return zz;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pledge-services', null, { reload: 'pledge-services' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pledge-services.delete', {
            parent: 'pledge-services',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pledge-services/pledge-services-delete-dialog.html',
                    controller: 'PledgeServicesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PledgeServices', function(PledgeServices) {
                            return PledgeServices.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pledge-services', null, { reload: 'pledge-services' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
