(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('HousingReportsDetailController', HousingReportsDetailController);

    HousingReportsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HousingReports', 'Property', 'AccommodationType', 'NumberOfBedRooms', 'Duration', 'Towns', 'Counties', 'Restrictions', 'AssessedBy', 'DateOfAssessment', 'Status', 'Matched'];

    function HousingReportsDetailController($scope, $rootScope, $stateParams, previousState, entity, HousingReports, Property, AccommodationType, NumberOfBedRooms, Duration, Towns, Counties, Restrictions, AssessedBy, DateOfAssessment, Status, Matched) {
        var vm = this;

        vm.housingReports = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:housingReportsUpdate', function(event, result) {
            vm.housingReports = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
