(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('DBUserProfileDialogController', DBUserProfileDialogController);

    DBUserProfileDialogController.$inject = ['$uibModalInstance', '$state', '$timeout', '$scope', '$stateParams', '$q', 'entity', 'UserProfile', 'User', 'VettedStatus', 'LanguageList'];

    function DBUserProfileDialogController ($uibModalInstance, $state, $timeout, $scope, $stateParams, $q, entity, UserProfile, User, VettedStatus, LanguageList) {
        var vm = this;

        vm.userProfile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.vettedstatuses = VettedStatus.query();
        vm.languagelists = LanguageList.query();
        vm.isOrganization = isOrganization;
        vm.isOrganizationFlag = vm.userProfile.organisationFlag;

        vm.authorities = [];

        setAuth();

        if (vm.isOrganizationFlag) vm.isOrganizationFlag = false;
        else vm.isOrganizationFlag = true;

        isOrganization();

        function setAuth(){
            if (typeof(Storage) !== "undefined") {
                vm.authorities = localStorage.getItem("authorities");
            }
        }

        function isOrganization (){
            if (vm.isOrganizationFlag) vm.isOrganizationFlag = false;
            else vm.isOrganizationFlag = true;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            var gaText = "virtual/profile/cancel/dashboard/profile-modal/profile-id-"+vm.userProfile.id;
            if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+ gaText });
            $uibModalInstance.dismiss('cancel');
            $state.go('dashboard-prepop');
        }

        function save () {
            var gaText = "virtual/profile/save/dashboard/profile-modal/profile-id-"+vm.userProfile.id;
            if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+ gaText });
            vm.isSaving = true;
            if (vm.userProfile.id !== null) {
                UserProfile.update(vm.userProfile, onSaveSuccess, onSaveError);
            } else {
                UserProfile.save(vm.userProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {

            $scope.$emit('pledgemasterApp:userProfileUpdate', result);
            $uibModalInstance.close(result);

            vm.isSaving = false;
            $state.go('dashboard-prepop');

        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.redcrossVettedExpDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
