(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeServiceDeleteController',PledgeServiceDeleteController);

    PledgeServiceDeleteController.$inject = ['$uibModalInstance', 'entity', 'PledgeService'];

    function PledgeServiceDeleteController($uibModalInstance, entity, PledgeService) {
        var vm = this;

        vm.pledgeService = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PledgeService.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
