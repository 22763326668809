(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossScreeningTwoDoneByDetailController', RedcrossScreeningTwoDoneByDetailController);

    RedcrossScreeningTwoDoneByDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RedcrossScreeningTwoDoneBy'];

    function RedcrossScreeningTwoDoneByDetailController($scope, $rootScope, $stateParams, previousState, entity, RedcrossScreeningTwoDoneBy) {
        var vm = this;

        vm.redcrossScreeningTwoDoneBy = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:redcrossScreeningTwoDoneByUpdate', function(event, result) {
            vm.redcrossScreeningTwoDoneBy = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
