(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccomsmCanYouAccomDetailController', AccomsmCanYouAccomDetailController);

    AccomsmCanYouAccomDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccomsmCanYouAccom'];

    function AccomsmCanYouAccomDetailController($scope, $rootScope, $stateParams, previousState, entity, AccomsmCanYouAccom) {
        var vm = this;

        vm.accomsmCanYouAccom = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:accomsmCanYouAccomUpdate', function(event, result) {
            vm.accomsmCanYouAccom = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
