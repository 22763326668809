(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommsLocalAmenitiesDialogController', AccommsLocalAmenitiesDialogController);

    AccommsLocalAmenitiesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccommsLocalAmenities'];

    function AccommsLocalAmenitiesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AccommsLocalAmenities) {
        var vm = this;

        vm.accommsLocalAmenities = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.accommsLocalAmenities.id !== null) {
                AccommsLocalAmenities.update(vm.accommsLocalAmenities, onSaveSuccess, onSaveError);
            } else {
                AccommsLocalAmenities.save(vm.accommsLocalAmenities, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:accommsLocalAmenitiesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
