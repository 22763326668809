(function() {
    'use strict';
    // An info slider that uses bootstrap carousel
    angular.module('pledgemasterApp').component('iconHeader', {
        templateUrl: 'app/components/icon-header/icon-header.html',
        bindings: {
            title: '@',
            subTitle: '@?',
            icon: '@?'
        }
    });
})();
