(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('GoodsCategoryTwoDetailController', GoodsCategoryTwoDetailController);

    GoodsCategoryTwoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GoodsCategoryTwo', 'GoodsCategoryOne'];

    function GoodsCategoryTwoDetailController($scope, $rootScope, $stateParams, previousState, entity, GoodsCategoryTwo, GoodsCategoryOne) {
        var vm = this;

        vm.goodsCategoryTwo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:goodsCategoryTwoUpdate', function(event, result) {
            vm.goodsCategoryTwo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
