(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeAccommodationController', PledgeAccommodationController);

    PledgeAccommodationController.$inject = ['DataUtils', 'PledgeAccommodation', 'PledgeAccommodationSearch'];

    function PledgeAccommodationController(DataUtils, PledgeAccommodation, PledgeAccommodationSearch) {

        var vm = this;

        vm.pledgeAccommodations = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            PledgeAccommodation.query(function(result) {
                vm.pledgeAccommodations = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PledgeAccommodationSearch.query({query: vm.searchQuery}, function(result) {
                vm.pledgeAccommodations = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
