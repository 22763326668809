(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeAccommodationsDetailController', PledgeAccommodationsDetailController);

    PledgeAccommodationsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'PledgeAccommodations', 'AccommsType', 'AccommsOwner', 'AccommsCondition', 'AccommsBedrooms', 'AccommsOccupancy', 'AccommCanYouAccom', 'AccommsFacilities', 'AccommsLocalAmenities'];

    function PledgeAccommodationsDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, PledgeAccommodations, AccommsType, AccommsOwner, AccommsCondition, AccommsBedrooms, AccommsOccupancy, AccommCanYouAccom, AccommsFacilities, AccommsLocalAmenities) {
        var vm = this;

        vm.pledgeAccommodations = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('pledgemasterApp:pledgeAccommodationsUpdate', function(event, result) {
            vm.pledgeAccommodations = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
