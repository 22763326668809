(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('AccommsConditionSearch', AccommsConditionSearch);

    AccommsConditionSearch.$inject = ['$resource'];

    function AccommsConditionSearch($resource) {
        var resourceUrl =  'api/_search/accomms-conditions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
