(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('GoodsCategoryOneDeleteController',GoodsCategoryOneDeleteController);

    GoodsCategoryOneDeleteController.$inject = ['$uibModalInstance', 'entity', 'GoodsCategoryOne'];

    function GoodsCategoryOneDeleteController($uibModalInstance, entity, GoodsCategoryOne) {
        var vm = this;

        vm.goodsCategoryOne = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GoodsCategoryOne.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
