(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('AccommsBedroomsSearch', AccommsBedroomsSearch);

    AccommsBedroomsSearch.$inject = ['$resource'];

    function AccommsBedroomsSearch($resource) {
        var resourceUrl =  'api/_search/accomms-bedrooms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
