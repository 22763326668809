(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccommCanYouAccomDetailController', AccommCanYouAccomDetailController);

    AccommCanYouAccomDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccommCanYouAccom'];

    function AccommCanYouAccomDetailController($scope, $rootScope, $stateParams, previousState, entity, AccommCanYouAccom) {
        var vm = this;

        vm.accommCanYouAccom = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:accommCanYouAccomUpdate', function(event, result) {
            vm.accommCanYouAccom = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
