(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$location', 'UserProfile', '$scope', 'Principal', 'LoginService', '$state', 'Auth', 'entity', 'pledgeGoods', 'pledgeAccommodations', 'pledgeServices'];

    function DashboardController ($location, UserProfile, $scope, Principal, LoginService, $state, Auth, entity, pledgeGoods, pledgeAccommodations, pledgeServices) {
        var vm = this;


        vm.save = save;
        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerPage = registerPage;
        vm.registerAccount = {};
        vm.success = null;
        vm.names = ["Yes", "No"];

        vm.openCreateModal = openCreateModal;
        vm.openEditModal = openEditModal;


        function openCreateModal(type){
            if (typeof(Storage) !== "undefined") {
                localStorage.setItem("authorities", vm.account.authorities)
            }


            var typeNames = ["accommodation", "goods", "services"];
            if (localStorage.getItem("authorities").indexOf("ROLE_ADMIN")<0) ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/pledge/open-create-modal/"+typeNames[type-2]+"/module"+type});
            else ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"admin/virtual/pledge/open-create-modal/"+typeNames[type-2]+"/module"+type});
            var newState = "dashboard-"+typeNames[type-2]+"-new";
            $state.go(newState);
        }
        function openEditModal(type, id){
            if (typeof(Storage) !== "undefined") {
                localStorage.setItem("authorities", vm.account.authorities)
            }


            var typeNames = ["profile", "accommodation", "goods", "services"];
            if (localStorage.getItem("authorities").indexOf("ROLE_ADMIN")<0) ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"virtual/pledge/open-edit-modal/"+typeNames[type-1]+"/module"+type});
            else ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+"admin/virtual/pledge/open-edit-modal/"+typeNames[type-1]+"/module"+type});
            var newState = "dashboard-"+typeNames[type-1]+"-edit";
            if (id > 0) $state.go(newState, {id: id});
            else $state.go(newState);
        }


        vm.userProfile = entity;
        vm.pledgedGoods = {};
        vm.pledgedServices = {};
        vm.pledgedAccommodations = {};

        vm.checkWidth = checkWidth;

        function checkWidth(){
            console.log(($(document).width() > 700))
            return  ($(document).width() > 700)
        }

        function getPledges(){
            vm.pledgedGoods = pledgeGoods;
            vm.pledgedServices = pledgeServices;
            vm.pledgedAccommodations = pledgeAccommodations;
        }


        vm.showGoods = false;
        vm.showServices = false;
        vm.showAccommodations = true;
        vm.showProfile = false;



        $('.row').css("width", "97%");
        $('.row').css("margin-left", "3%");

        checkUserAgent();

        function checkUserAgent(){
            var ua = window.navigator.userAgent.toString().toLowerCase();
            if ((ua.indexOf("iphone")>=0) && (ua.indexOf("safari")>=0) && (ua.indexOf("chrome")<0)){
                $('.row').css("width", "90%");
                $('.row').css("margin-left", "5%");
            }
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        getProfile();

        angular.element(document).ready(function () {
            console.log('page loading completed');
           // setTableViews();
        });

        // No Longer need this as we can use ternary operator in element to test existence of data via 2-way binding
        function setTableViews(){
            if  (vm.pledgedAccommodations.length == 0) {
                if (!vm.pledgedAccommodations.$resolved) vm.showAccommodations = true;
                else vm.showAccommodations = false;
            }
            if (vm.pledgedServices.length != 0) vm.showServices = true;
            if (vm.pledgedGoods.length != 0) vm.showGoods = true;
        }

        function wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
                end = new Date().getTime();
            }
       }

        function getProfile(){
            if (vm.userProfile.id == null){
               $state.go('about-profile-new');
            }
        }

        $scope.gotoBottom = function() {
            // set the location.hash to the id of
            // the element you wish to scroll to.
            $location.hash('terms');

            // call $anchorScroll()
           // $anchorScroll();
        };

        function save(){
            vm.isSaving = true;
            if (vm.userProfile.id !== null) {
                UserProfile.update(vm.userProfile, onSaveSuccess, onSaveError);
            } else {
                UserProfile.save(vm.userProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:userProfileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.account.authorities.indexOf("ROLE_ADMIN")<0) {
                    console.log ("ddd")
                    getPledges();}
            });
        }
        function registerPage () {
            $state.go('register');
        }
        function register () {

                vm.registerAccount.langKey = 'en';
                vm.registerAccount.login = vm.registerAccount.email;
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;

                Auth.createAccount(vm.registerAccount).then(function () {
                    vm.success = 'OK';
                    Auth.login({
                        username: vm.registerAccount.login,
                        password: vm.registerAccount.password,
                        rememberMe: false
                    }).then(function () {
                        //vm.authenticationError = false;
                        //$uibModalInstance.close();
                        if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                            $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                            $state.go('verification');
                        }

                        $rootScope.$broadcast('authenticationSuccess');

                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        if (Auth.getPreviousState()) {
                            var previousState = Auth.getPreviousState();
                            Auth.resetPreviousState();
                            $state.go(previousState.name, previousState.params);
                        }
                    }).catch(function () {
                        vm.authenticationError = true;
                    });
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });

        }
    }
})();
