(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('DBPledgeGoodsDialogController', DBPledgeGoodsDialogController);

    DBPledgeGoodsDialogController.$inject = ['profile', '$timeout', '$scope', 'Auth', '$uibModalInstance', 'entity', 'PledgeGoods', 'GoodsCategoryOne', 'GoodsCategoryTwo', 'GoodsCategoryThree', 'GoodsQuantity', 'GoodsCondition'];

    function DBPledgeGoodsDialogController (profile, $timeout, $scope, Auth, $uibModalInstance, entity, PledgeGoods, GoodsCategoryOne, GoodsCategoryTwo, GoodsCategoryThree, GoodsQuantity, GoodsCondition) {
        var vm = this;

        vm.pledgeGoods = entity;
        vm.profile = profile;
        vm.useAddress = vm.pledgeGoods.useProfileAddress;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.getAddress = getAddress;
        vm.save = save;
        vm.goodscategoryones = GoodsCategoryOne.query();
        vm.goodscategorytwos = GoodsCategoryTwo.query();
        vm.goodscategorythrees = GoodsCategoryThree.query();
        vm.goodsquantities = GoodsQuantity.query();
        vm.goodsconditions = GoodsCondition.query();

        vm.listTwoVisible = listTwoVisible;
        vm.listThreeVisible = listThreeVisible;
        vm.quantityVisible = quantityVisible;

        vm.listTwo = {};
        vm.listThree = {}
        vm.quantity = {};

        vm.authorities = [];

        setAuth();

        if (vm.useAddress) vm.useAddress = false;
        else vm.useAddress = true;

        getAddress();

        function setAuth(){
            if (typeof(Storage) !== "undefined") {
                vm.authorities = localStorage.getItem("authorities");
            }
        }

        function listTwoVisible(){
            vm.listTwo = {};
            console.log(vm.pledgeGoods.goodsListOne)
            if (vm.pledgeGoods.goodsListOne != null) {
                var filt = vm.goodscategorytwos.filter(function (e) {
                    return e.goodsOne.id == vm.pledgeGoods.goodsListOne.id;
                })
            }
            vm.listTwo = filt;
            if (vm.listTwo != null) listThreeVisible();
        }
        function listThreeVisible(){
            vm.listThree = {};
            console.log("_3")
            if (vm.listTwo.length > 0) {
                if (vm.pledgeGoods.goodsListTwo != null) {
                    var filt = vm.goodscategorythrees.filter(function (e) {
                        return e.goodsTwo.id == vm.pledgeGoods.goodsListTwo.id;
                    })
                }
            }
            vm.listThree = filt;
            //
                quantityVisible();
        }
        function quantityVisible(){
            vm.quantity = {};
            if (vm.listThree != null) {
                if (vm.listThree.length > 0) {
                    //console.log(vm.pledgeGoods.goodsListThree)
                    if (vm.pledgeGoods.goodsListThree != null) {
                        var filt = vm.goodsquantities.filter(function (e) {
                            console.log(e)
                            console.log(vm.pledgeGoods.goodsListThree)
                            var number = true;
                            var nQuant = true;
                            if (vm.pledgeGoods.goodsListThree.isNumberedQuantity == null) number = false;
                            if (e.isNumber == null) nQuant = false;
                            //console.log(vm.pledgeGoods.goodsListThree)
                            return nQuant == number;
                            // return true;
                        })
                        vm.quantity = filt;
                    }
                }
            }
            // if (vm.pledgeGoods.goodsListOne.select == "Technology") {
            //     var filt = vm.goodsquantities.filter(function (e) {
            //         return e.isNumber;
            //     })
            // }

        }

        function getAddress() {

            if (vm.useAddress){
                vm.useAddress = false;
                vm.pledgeGoods.goodsAddress1 = "";
                vm.pledgeGoods.goodsAddress2 = "";
                vm.pledgeGoods.goodsAddress3 = "";
                vm.pledgeGoods.goodsCityTownVillage = "";
                vm.pledgeGoods.goodsAreaCounty = "";
                vm.pledgeGoods.goodsPostCode = "";
                vm.pledgeGoods.goodsCountry = "";
            }
            else {
                vm.useAddress = true;
                vm.pledgeGoods.goodsAddress1 = vm.profile.profileAddress1;
                vm.pledgeGoods.goodsAddress2 = vm.profile.profileAddress2;
                vm.pledgeGoods.goodsAddress3 = vm.profile.profileAddress3;
                vm.pledgeGoods.goodsCityTownVillage = vm.profile.profileAddressCityTownVillage;
                vm.pledgeGoods.goodsAreaCounty = vm.profile.profileAddressArea;
                vm.pledgeGoods.goodsPostCode = vm.profile.profileAddressAreaCode;
                vm.pledgeGoods.goodsCountry = vm.profile.profileAddressCountry;
            }

        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            var ce = "edit";
            if (vm.pledgeGoods.id == null) ce = "create"
            var gaText = "virtual/pledge/cancel/dashboard/goods-"+ce+"-modal/pledge-id-"+vm.pledgeGoods.id;
            if (vm.authorities.indexOf("ROLE_USER")>=0) gaText = "admin/" + gaText;
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash + gaText });
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            var cat1 = vm.pledgeGoods.goodsListOne;
            var cat2 = vm.pledgeGoods.goodsListTwo;
            var cat3 = vm.pledgeGoods.goodsListThree;
            var cat4 = vm.pledgeGoods.goodsAreaCounty;
            vm.isSaving = true;
            if (vm.pledgeGoods.id !== null) {
                var gaText = "virtual/pledge/save/dashboard/goods-edit-modal" +
                    "/category1-"+cat1+
                    "/category2-"+cat2+
                    "/category3-"+cat3+
                    "/category4-"+cat4+
                    "/pledge-id-"+vm.pledgeGoods.id;
                if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
                ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+ gaText });
                PledgeGoods.update(vm.pledgeGoods, onSaveSuccess, onSaveError);
            } else {
                var gaText = "virtual/pledge/save/dashboard/goods-create-modal" +
                    "/category1-"+cat1+
                    "/category2-"+cat2+
                    "/category3-"+cat3+
                    "/category4-"+cat4+
                    "/pledge-id-"+vm.pledgeGoods.id;
                if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
                ga('send', 'pageView',{ 'page': location.pathname + location.search + location.hash + gaText});
                PledgeGoods.save(vm.pledgeGoods, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:pledgeGoodsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.goodsStartDate = false;
        vm.datePickerOpenStatus.goodsEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
