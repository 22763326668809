(function() {
    'use strict';


    // Wrap the ui carousel to only be active for mobile clients

    angular
        .module('pledgemasterApp')
        .directive('carouselMobileOnly', carouselMobileOnly)
        .directive('carouselSlideMobileOnly', carouselSlideMobileOnly)
        .directive('isMobile', isMobile)
        .filter('chunk', chunk);

    function chunk () {
        function cacheIt(func) {
            var cache = {};
            return function(arg) {
                // if the function has been called with the argument
                // short circuit and use cached value, otherwise call the
                // cached function with the argument and save it to the cache as well then return
                return cache[arg] ? cache[arg] : cache[arg] = func(arg);
            };
        }
        // unchanged from your example apart from we are no longer directly returning this   ?
        function chunk(items, chunkSize) {
            var chunks = [];
            if (angular.isArray(items)) {
                if (isNaN(chunkSize))
                    chunkSize = 2;
                for (var i = 0; i < items.length; i += chunkSize) {
                    chunks.push(items.slice(i, i + chunkSize));
                }
            } else {
                console.log("items is not an array: " + angular.toJson(items));
            }
            return chunks;
        }
        // now we return the cached or memoized version of our chunk function
        // if you want to use lodash this is really easy since there is already a chunk and memoize function all above code would be removed
        // this return would simply be: return _.memoize(_.chunk);

        return cacheIt(chunk);
    }

    isMobile.$inject = ['$compile'];

    // TODO: for now doesn't react to window resizing
    function isMobile($compile) {
        return {
            terminal: true,
            priority: 10000,
            link: function($scope, element, attrs, ctrl) {
                element.removeAttr('is-mobile');
                if ($(document).width() < 768) {
                    element.attr(attrs.isMobile, 'true');
                    $compile(element)($scope);
                }
            }
        }
    }

    function carouselMobileOnly() {
        var directive = {
            restrict: 'A',
            transclude: true,
            scope: {
                isMobile: '='
            },
// <div ng-switch on="video">
//     <div ng-switch-when="video.large">
//         <!-- code to render a large video block-->
//     </div>
//     <div ng-switch-default>
//         <!-- code to render the regular video block -->
//     </div>
// </div>
            template: '<div ng-switch="isMobile"><div ng-switch-when="true" uib-carousel class="is-mobile" ng-transclude></div><div ng-switch-default class="not-mobile" ng-transclude></div></div>',
            // link: linkFunc
        };

        return directive;

        /* private helper methods */
        function linkFunc(scope) {

        }
    }


    function carouselSlideMobileOnly() {
        var directive = {
            // require: '^^carousel-mobile-only',
            restrict: 'A',
            transclude: true,
            scope: {
                isMobile: '='
            },
            template: '<div ng-switch="isMobile"><div ng-switch-when="true" uib-slide ng-transclude></div><div ng-switch-default uib-slide ng-transclude></div></div>',
            // link: linkFunc
        };

        return directive;

        /* private helper methods */
        function linkFunc(scope) {

        }
    }

})();
