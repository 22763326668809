(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('RedcrossScreeningOneDoneByDetailController', RedcrossScreeningOneDoneByDetailController);

    RedcrossScreeningOneDoneByDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RedcrossScreeningOneDoneBy'];

    function RedcrossScreeningOneDoneByDetailController($scope, $rootScope, $stateParams, previousState, entity, RedcrossScreeningOneDoneBy) {
        var vm = this;

        vm.redcrossScreeningOneDoneBy = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:redcrossScreeningOneDoneByUpdate', function(event, result) {
            vm.redcrossScreeningOneDoneBy = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
