(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeGoodsDialogController', PledgeGoodsDialogController);

    PledgeGoodsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PledgeGoods', 'GoodsCategoryOne', 'GoodsCategoryTwo', 'GoodsCategoryThree', 'GoodsQuantity', 'GoodsCondition'];

    function PledgeGoodsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PledgeGoods, GoodsCategoryOne, GoodsCategoryTwo, GoodsCategoryThree, GoodsQuantity, GoodsCondition) {
        var vm = this;

        vm.pledgeGoods = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.goodscategoryones = GoodsCategoryOne.query();
        vm.goodscategorytwos = GoodsCategoryTwo.query();
        vm.goodscategorythrees = GoodsCategoryThree.query();
        vm.goodsquantities = GoodsQuantity.query();
        vm.goodsconditions = GoodsCondition.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.pledgeGoods.id !== null) {
                PledgeGoods.update(vm.pledgeGoods, onSaveSuccess, onSaveError);
            } else {
                PledgeGoods.save(vm.pledgeGoods, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:pledgeGoodsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.goodsStartDate = false;
        vm.datePickerOpenStatus.goodsEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
