(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeAccommodationDeleteController',PledgeAccommodationDeleteController);

    PledgeAccommodationDeleteController.$inject = ['$uibModalInstance', 'entity', 'PledgeAccommodation'];

    function PledgeAccommodationDeleteController($uibModalInstance, entity, PledgeAccommodation) {
        var vm = this;

        vm.pledgeAccommodation = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PledgeAccommodation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
