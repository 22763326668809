(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('PledgeServicesDialogController', PledgeServicesDialogController);

    PledgeServicesDialogController.$inject = ['profile', 'UserProfileById', 'UserProfile', '$timeout', '$resource', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'PledgeServices', 'ServiceCategoryOne', 'ServiceCategoryTwo', 'ServiceCategoryThree', 'ServiceTravel'];

    function PledgeServicesDialogController (profile, UserProfileById, UserProfile, $timeout, $resource, $scope, $stateParams, $uibModalInstance, DataUtils, entity, PledgeServices, ServiceCategoryOne, ServiceCategoryTwo, ServiceCategoryThree, ServiceTravel) {
        var vm = this;

        vm.pledgeServices = entity;
        vm.profile = profile;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.useAddress = false;
        vm.getAddress = getAddress;
        vm.servicecategoryones = ServiceCategoryOne.query();
        vm.servicecategorytwos = ServiceCategoryTwo.query();
        vm.servicecategorythrees = ServiceCategoryThree.query();
        vm.servicetravels = ServiceTravel.query();




        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.pledgeServices.id !== null) {
                PledgeServices.update(vm.pledgeServices, onSaveSuccess, onSaveError);
            } else {
                PledgeServices.save(vm.pledgeServices, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:pledgeServicesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getAddress() {

           if (vm.useAddress){
               vm.useAddress = false;
               vm.pledgeServices.serviceAddress1 = "";
               vm.pledgeServices.serviceAddress2 = "";
               vm.pledgeServices.serviceAddress3 = "";
               vm.pledgeServices.serviceCityTownVillage = "";
               vm.pledgeServices.serviceAreaCounty = "";
               vm.pledgeServices.servicePostCode = "";
               vm.pledgeServices.serviceCountry = "";
           }
           else {
               vm.useAddress = true;
               vm.pledgeServices.serviceAddress1 = vm.profile.profileAddress1;
               vm.pledgeServices.serviceAddress2 = vm.profile.profileAddress2;
               vm.pledgeServices.serviceAddress3 = vm.profile.profileAddress3;
               vm.pledgeServices.serviceCityTownVillage = vm.profile.profileAddressAreaCode;
               vm.pledgeServices.serviceAreaCounty = vm.profile.profileAddressArea;
               vm.pledgeServices.servicePostCode = vm.profile.profileAddressAreaCode;
               vm.pledgeServices.serviceCountry = vm.profile.profileAddressCountry;
           }

        }

        vm.datePickerOpenStatus.serviceStartDate = false;
        vm.datePickerOpenStatus.serviceEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
