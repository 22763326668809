(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('VettedStatusSearch', VettedStatusSearch);

    VettedStatusSearch.$inject = ['$resource'];

    function VettedStatusSearch($resource) {
        var resourceUrl =  'api/_search/vetted-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
