(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('AccomCanYouAccomDetailController', AccomCanYouAccomDetailController);

    AccomCanYouAccomDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccomCanYouAccom', 'PledgeAccommodation'];

    function AccomCanYouAccomDetailController($scope, $rootScope, $stateParams, previousState, entity, AccomCanYouAccom, PledgeAccommodation) {
        var vm = this;

        vm.accomCanYouAccom = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('pledgemasterApp:accomCanYouAccomUpdate', function(event, result) {
            vm.accomCanYouAccom = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
