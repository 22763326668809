(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .controller('DBPledgeAccommodationsDialogController', DBPledgeAccommodationsDialogController);

    DBPledgeAccommodationsDialogController.$inject = ['profile', '$timeout', '$scope', '$state', '$uibModalInstance', 'DataUtils', 'entity', 'PledgeAccommodations', 'AccommsType', 'AccommsOwner', 'AccommsCondition', 'AccommsBedrooms', 'AccommsOccupancy', 'AccommCanYouAccom', 'AccommsFacilities', 'AccommsLocalAmenities'];

    function DBPledgeAccommodationsDialogController (profile, $timeout, $scope, $state, $uibModalInstance, DataUtils, entity, PledgeAccommodations, AccommsType, AccommsOwner, AccommsCondition, AccommsBedrooms, AccommsOccupancy, AccommCanYouAccom, AccommsFacilities, AccommsLocalAmenities) {
        var vm = this;

        vm.pledgeAccommodations = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.accommstypes = AccommsType.query();
        vm.accommsowners = AccommsOwner.query();
        vm.accommsconditions = AccommsCondition.query();
        vm.accommsbedrooms = AccommsBedrooms.query();
        vm.accommsoccupancies = AccommsOccupancy.query();
        vm.accommcanyouaccoms = AccommCanYouAccom.query();
        vm.accommsfacilities = AccommsFacilities.query();
        vm.accommslocalamenities = AccommsLocalAmenities.query();

        vm.profile = profile;
        vm.getAddress = getAddress;
        vm.useAddress = vm.pledgeAccommodations.useProfileAddress;

        vm.authorities = [];

        setAuth();

        if (vm.useAddress) vm.useAddress = false;
        else vm.useAddress = true;

        getAddress();

        function setAuth(){
            if (typeof(Storage) !== "undefined") {
                vm.authorities = localStorage.getItem("authorities");
            }
        }

        function getAddress() {

            if (vm.useAddress){
                vm.useAddress = false;
                vm.pledgeAccommodations.accommodationAddress1 = "";
                vm.pledgeAccommodations.accommodationAddress2 = "";
                vm.pledgeAccommodations.accommodationAddress3 = "";
                vm.pledgeAccommodations.accommodationCityTown = "";
                vm.pledgeAccommodations.accommodationAreaCounty = "";
                vm.pledgeAccommodations.accommodationPostCode = "";
                vm.pledgeAccommodations.accommodationCountry = "";
            }
            else {
                vm.useAddress = true;
                vm.pledgeAccommodations.accommodationAddress1 = vm.profile.profileAddress1;
                vm.pledgeAccommodations.accommodationAddress2 = vm.profile.profileAddress2;
                vm.pledgeAccommodations.accommodationAddress3 = vm.profile.profileAddress3;
                vm.pledgeAccommodations.accommodationCityTown = vm.profile.profileAddressCityTownVillage;
                vm.pledgeAccommodations.accommodationAreaCounty = vm.profile.profileAddressArea;
                vm.pledgeAccommodations.accommodationPostCode = vm.profile.profileAddressAreaCode;
                vm.pledgeAccommodations.accommodationCountry = vm.profile.profileAddressCountry;
            }

        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            var ce = "edit";
            if (vm.pledgeAccommodations.id == null) ce = "create"
            var gaText = "virtual/pledge/cancel/dashboard/accommodation-"+ce+"-modal/pledge-id-"+vm.pledgeAccommodations.id;
            if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
            ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash + gaText });
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            var cat1 = vm.occupancy;
            var cat2 = vm.accommsbedrooms;
            var cat3 = vm.accommstypes;
            var cat4 = vm.pledgeAccommodations.goodsAreaCounty;
            vm.isSaving = true;
            if (vm.pledgeAccommodations.id !== null) {
                var gaText = "virtual/pledge/save/dashboard/accommodation-edit-modal" +
                    "/category1-"+cat1+
                    "/category2-"+cat2+
                    "/category3-"+cat3+
                    "/category4-"+cat4+
                    "/pledge-id-"+vm.pledgeAccommodations.id;
                if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
                ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+gaText });
                PledgeAccommodations.update(vm.pledgeAccommodations, onSaveSuccess, onSaveError);
            } else {
                var gaText = "virtual/pledge/save/dashboard/accommodation-create-modal" +
                    "/category1-"+cat1+
                    "/category2-"+cat2+
                    "/category3-"+cat3+
                    "/category4-"+cat4+
                    "/pledge-id-"+vm.pledgeAccommodations.id;
                if (vm.authorities.indexOf("ROLE_ADMIN")>=0) gaText = "admin/" + gaText;
                ga('send', 'pageView', { 'page': location.pathname + location.search + location.hash+gaText});
                PledgeAccommodations.save(vm.pledgeAccommodations, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('pledgemasterApp:pledgeAccommodationsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.accommodationStartDate = false;
        vm.datePickerOpenStatus.accommodationEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
