(function() {
    'use strict';

    angular
        .module('pledgemasterApp')
        .factory('AccommodationFacilitiesSearch', AccommodationFacilitiesSearch);

    AccommodationFacilitiesSearch.$inject = ['$resource'];

    function AccommodationFacilitiesSearch($resource) {
        var resourceUrl =  'api/_search/accommodation-facilities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
